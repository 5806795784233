export function PowerOffIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2399 4.42676C13.0788 5.26595 13.6501 6.33505 13.8814 7.49889C14.1128 8.66272 13.9939 9.86902 13.5397 10.9653C13.0855 12.0615 12.3165 12.9985 11.3298 13.6577C10.3432 14.3169 9.18319 14.6687 7.99659 14.6687C6.80998 14.6687 5.65002 14.3169 4.66337 13.6577C3.67672 12.9985 2.90768 12.0615 2.4535 10.9653C1.99931 9.86902 1.88038 8.66272 2.11173 7.49889C2.34308 6.33505 2.91433 5.26595 3.75325 4.42676"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.33325V7.99992"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
