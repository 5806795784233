import Chart from 'chart.js/auto';
import { useRef, useEffect } from 'react';
import 'chartjs-plugin-style';
import { useIntl } from 'react-intl';
import 'chartjs-plugin-style';

import { useDevice } from 'src/hooks/useDevice';

import { dataSets } from './weight-loss-by-percentage-chart-data';
import tooltips from './weight-loss-by-percentage-chart-tooltips';
import messages from './weight-loss-by-percentage-chart.messages';

Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";

type Props = {
  id: string;
  data: Record<string, any[]>;
};

export function WeightLossByPercentageChart({ id, data }: Props) {
  const intl = useIntl();

  const canvasRef = useRef<any>(null);
  const chartRef = useRef<any>(null);

  const { isTablet } = useDevice();

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const labels = [
      intl.formatMessage(messages.Month1),
      intl.formatMessage(messages.Month2),
      intl.formatMessage(messages.Month3),
      intl.formatMessage(messages.Month4),
      intl.formatMessage(messages.Month5),
      intl.formatMessage(messages.Month6),
      intl.formatMessage(messages.Month7),
      intl.formatMessage(messages.Month8),
      intl.formatMessage(messages.Month9),
      intl.formatMessage(messages.Month10),
      intl.formatMessage(messages.Month11),
      intl.formatMessage(messages.Month12),
    ];

    // NOTE: Splice the array so we don't show empty months.
    const totalMonths: number = data.Median?.length;
    const labelsWithoutCurrentMonth = labels.splice(0, totalMonths - 1);
    const dataWithoutCurrentMonth = Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value.splice(0, totalMonths - 1),
      }),
      {}
    );

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labelsWithoutCurrentMonth,
        datasets: dataSets(dataWithoutCurrentMonth, isTablet, intl),
      },
      options: {
        // Customize chart options
        // fill: 'red',
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: isTablet ? 15 : 25,
            bottom: 0,
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: intl.formatMessage(messages.monthsInto),
              font: {
                family: 'GT-America-Standard-Medium',
                size: isTablet ? 6 : 13,
              },
              padding: isTablet ? 5 : 20,
            },
            ticks: {
              display: true,
              font: {
                size: isTablet ? 6 : 13,
              },
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: 'TBWL %',
              font: {
                family: 'GT-America-Standard-Medium',
                size: isTablet ? 6 : 13,
              },
              padding: isTablet ? 5 : 20,
            },
            ticks: {
              display: true,
              padding: isTablet ? 5 : 20,
              font: {
                size: isTablet ? 6 : 13,
              },
              callback: (value) => ` ${value}% `,
            },
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: tooltips,
          legend: {
            display: false,
          },
        },
      },
    });
  }, [isTablet, data, intl]);

  return (
    <div>
      <canvas id={id} ref={canvasRef} />
    </div>
  );
}
