import ReactLoading from 'react-loading';

import colors from 'src/styles/theme/colors';

type Props = {
  size?: string;
};

export function InlineLoader({ size = '64px' }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <ReactLoading color={colors.RiseBlue} type="spokes" width={size} height={size} />
    </div>
  );
}
