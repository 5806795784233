export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isNumberArray(value: unknown): value is number[] {
  return Array.isArray(value) && value.every(isNumber);
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

export function isDefined<T>(value: T | undefined): value is T {
  return !isUndefined(value);
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isNotNull<T>(value: T | null): value is T {
  return !isNull(value);
}

export function isError(value: unknown): value is Error {
  return value instanceof Error;
}

export function isErrorLike(value: unknown): value is { name: string; message: string } {
  return typeof value === 'object' && isNotNull(value) && 'name' in value && 'message' in value;
}

export function isAwsError(value: unknown): value is { code: string; message: string } {
  return typeof value === 'object' && isNotNull(value) && 'code' in value && 'message' in value;
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && isNotNull(value) && !Array.isArray(value);
}
