import { ClinicTreatment } from './clinic-treatment';

export const ClinicFeatures = {
  ClinicLedOnboarding: 'add_patient',
  RiskAnalysis: 'at_risk',
  LibraryPlusContent: 'library_plus',
  ExportData: 'download_data',
  Messages: 'messages',
  Notes: 'notes',
  Video: 'video',
  DailyActions: 'daily_actions',
  ManualWeightEntry: 'manual_weight_entry',
  PatientGrouping: 'patient_grouping',
  EmailNotifications: 'email_notifications',
} as const;
export type ClinicFeature = (typeof ClinicFeatures)[keyof typeof ClinicFeatures];

export type ClinicSettings = {
  ClinicID: string;
  features?: string[];
  weightUnit?: string;
  treatmentsOffered?: number[];
};

export const isValidClinicFeature = (x: string): x is ClinicFeature =>
  Object.values(ClinicFeatures).includes(x as ClinicFeature);

export function clinicHasFeatureEnabled(
  settings: Partial<ClinicSettings>,
  feature: ClinicFeature
): boolean {
  return Boolean(settings?.features?.includes(feature));
}

export function isDailyActionsEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.DailyActions));
}

export function isMessagesEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.Messages));
}

export function isVideoEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.Video));
}

export function isNotesEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.Notes));
}

export function isClinicLedOnboardingEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.ClinicLedOnboarding));
}

export function isRiskAnalyisEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.RiskAnalysis));
}

export function isLibraryPlusContentEnabled(settings?: Partial<ClinicSettings>) {
  return Boolean(settings?.features?.includes(ClinicFeatures.LibraryPlusContent));
}

export function isManualWeightEntryEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.ManualWeightEntry));
}

export function isExportDataEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.ExportData));
}

export function isPatientGroupingEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.PatientGrouping));
}

export function isEmailNotificationsEnable(settings: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeatures.EmailNotifications));
}

export const DEFAULT_WEIGHT_UNIT = 'kg';

export function getDefaultClinicSettings(clinicId: string | undefined): ClinicSettings {
  return {
    weightUnit: DEFAULT_WEIGHT_UNIT,
    features: [ClinicFeatures.Notes],
    ClinicID: clinicId || '',
    treatmentsOffered: [1, 5],
  };
}

export function findMostUsedWeightUnit(clinicsSettings: ClinicSettings[]) {
  const unitsVotes = clinicsSettings.reduce(
    (votes, s) => {
      if (!s.weightUnit) {
        return votes;
      }

      const unit = s.weightUnit;

      const unitVotes = votes[unit] ?? 0;

      return {
        ...votes,
        [unit]: unitVotes + 1,
      };
    },
    {} as { [k: string]: number }
  );

  if (Object.keys(unitsVotes).length === 0) {
    return DEFAULT_WEIGHT_UNIT;
  }

  const [unit] = Object.entries(unitsVotes).reduce((mostVoted, unitVotes) => {
    if (unitVotes[1] > mostVoted[1]) {
      return unitVotes;
    }

    return mostVoted;
  });

  return unit;
}

export function filterOfferedTreatments(settings: ClinicSettings, treatments: ClinicTreatment[]) {
  return treatments.filter((t) => settings.treatmentsOffered?.includes(parseInt(t.treatmentId)));
}
