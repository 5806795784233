import { PatientTreatment } from '@allurion/domain';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { toastWarning } from 'src/components/ui/toasts';
import { Logger } from 'src/services/Logger';

export const PATIENT_TREATMENTS = (patientId: string) => `/patients/${patientId}/treatments`;
export const PATIENT_TREATMENTS_ID = (patientId: string, treatmentId: string) =>
  `/patients/${patientId}/treatments/${treatmentId}`;

export function usePatientTreatments(patientId?: string) {
  const intl = useIntl();
  const url = patientId && buildUrl(PATIENT_TREATMENTS(patientId));
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ treatments: PatientTreatment[] }>(_url)
        .then((res) => res.treatments)
        .catch((error) => {
          Logger.captureException(error);
          toastWarning(
            intl.formatMessage({
              id: 'error.fetching-patient-treatments',
              defaultMessage: 'Internal error: failed to fetch patient treatments.',
            })
          );

          return [];
        }),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  const createTreatment = useCallback(
    async (_patientId: string, newTreatment: PatientTreatmentBody) => {
      try {
        await createPatientTreatment(_patientId, newTreatment);
        await mutate();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      }
    },
    [mutate]
  );

  const updateTreatment = useCallback(
    async (_patientId: string, treatmentId: number, treatment: PatientTreatmentBody) => {
      try {
        await updatePatientTreatment(_patientId, treatmentId, treatment);
        await mutate();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      }
    },
    [mutate]
  );

  const deleteTreatment = useCallback(
    async (_patientId: string, treatment: PatientTreatment) => {
      try {
        await deletePatientTreatment(_patientId, treatment.TreatmentUserID);
        await mutate();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      }
    },
    [mutate]
  );

  return {
    isLoading: isLoading || isValidating,
    treatments: data ?? [],
    error,
    createTreatment,
    updateTreatment,
    deleteTreatment,
  };
}

type PatientTreatmentBody = {
  TreatmentTypeID: number;
  StartDate: string;
  EndDate?: string;
};

function createPatientTreatment(patientId: string, data: PatientTreatmentBody) {
  return InsightsApi.post(buildUrl(PATIENT_TREATMENTS(patientId)), cleanUpEndDate(data));
}

function deletePatientTreatment(patientId: string, treatmentId: number) {
  return InsightsApi.delete(buildUrl(PATIENT_TREATMENTS_ID(patientId, treatmentId.toString())));
}

export function updatePatientTreatment(
  patientId: string,
  treatmentId: number,
  data: PatientTreatmentBody
) {
  return InsightsApi.put(
    buildUrl(PATIENT_TREATMENTS_ID(patientId, treatmentId.toString())),
    cleanUpEndDate(data)
  );
}

function cleanUpEndDate(data: PatientTreatmentBody) {
  if (data.EndDate === '') {
    data.EndDate = undefined;
  }

  return data;
}
