import { Card, PageHeader } from '@allurion/ui';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { InvitePatientApiPayload } from 'src/pages/InvitePatientPage/InvitePatient.types';

import { Container, InnerContainer } from '../shared-page-elements';

import translations from './InvitePatient.translations';

import styles from './InvitePatientSuccess.module.scss';

type Props = {
  data: InvitePatientApiPayload;
  reset: () => void;
};

export function InvitePatientSuccess({ data, reset }: Props) {
  const intl = useIntl();
  const { toClinicPage, toPatientPage } = useAppNavigate();
  const { clinicId } = useParams();

  const { firstName, lastName, email, patientId } = data;
  const name = `${firstName} ${lastName}`;

  return (
    <>
      <PageHeader title={intl.formatMessage(translations.header)} />
      <Container>
        <InnerContainer>
          <Card>
            <Title>{intl.formatMessage(translations.added, { name })}</Title>
            <Description>
              <Invitation>{intl.formatMessage(translations.invitation, { email })}</Invitation>
              <Instructions>{intl.formatMessage(translations.instructions, { name })}</Instructions>
              <Expiration>{intl.formatMessage(translations.expiration)}</Expiration>
            </Description>
            <div className={styles.actions}>
              <TrackedButton
                label={intl.formatMessage(translations.backToClinicBtn)}
                onClick={backToClinic}
                trackLabel="confirm-invite-patient-success"
                variant="outlined"
              />
              {patientId && (
                <TrackedButton
                  label={intl.formatMessage(translations.viewPatientBtn)}
                  onClick={viewPatient}
                  trackLabel="confirm-invite-patient-success"
                  variant="secondary"
                />
              )}
              <TrackedButton
                label={intl.formatMessage(translations.inviteAnotherPatientBtn)}
                onClick={inviteAnotherPatient}
                trackLabel="confirm-invite-patient-success"
              />
            </div>
          </Card>
        </InnerContainer>
      </Container>
    </>
  );

  function backToClinic() {
    toClinicPage(clinicId);
  }

  function viewPatient() {
    toPatientPage(clinicId!, patientId!);
  }

  function inviteAnotherPatient() {
    reset();
  }
}

const Description = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.h2`
  font-weight: bolder;
  font-size: 28px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  margin-bottom: 35px;
`;

const Invitation = styled.p`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  padding-bottom: 10px;
`;

const Instructions = styled.p`
  font-family: 'GT-America-Standard-Light', sans-serif;
  padding-bottom: 10px;
`;

const Expiration = styled.p`
  font-family: 'GT-America-Standard-Light', sans-serif;
`;
