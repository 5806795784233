import { isDefined, isNotNull, isNull, isUndefined } from '@allurion/utils';
import { useCallback, useMemo, useState } from 'react';

export function useTableSort<T>(
  data: T[],
  defaultSortBy: string,
  sortType: Partial<Record<keyof T, 'string' | 'number' | ((a: unknown, b: unknown) => number)>>,
  tableName?: string
) {
  const sortByKey = tableName && `sort-by-${tableName}`;
  const initialSortBy = useMemo(() => {
    if (!sortByKey) {
      return defaultSortBy;
    }

    const state = sessionStorage.getItem(sortByKey);

    if (state) {
      return state;
    }

    return defaultSortBy;
  }, [defaultSortBy, sortByKey]);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const sortDirection = sortBy[0] === '+' ? 1 : -1;
  const sortField = sortBy.slice(1) as keyof T;
  const sortFieldType = sortType[sortField] ?? 'string';

  const getColumnValue = useCallback(
    (row: T, field: keyof T) => {
      const value = row[field];

      if (isNull(value) || isUndefined(value)) {
        return value;
      }

      if (sortFieldType === 'string') {
        return String(value).toLowerCase();
      }

      if (sortFieldType === 'number') {
        return Number(value);
      }

      return value;
    },
    [sortFieldType]
  );

  const comparisonFn = useCallback(
    (a: T, b: T): number => {
      const aValue = getColumnValue(a, sortField);
      const bValue = getColumnValue(b, sortField);

      // Nulls and undefineds should always be at the bottom
      if (isNull(aValue) || isUndefined(aValue)) {
        return isNotNull(bValue) && isDefined(bValue) ? 1 : 0;
      }
      if (isNull(bValue) || isUndefined(bValue)) {
        return isNotNull(aValue) && isDefined(aValue) ? -1 : 0;
      }

      if (typeof sortFieldType === 'function') {
        return sortFieldType(aValue, bValue) * sortDirection;
      }

      if (aValue < bValue) {
        return -1 * sortDirection;
      }

      if (aValue > bValue) {
        return 1 * sortDirection;
      }

      return 0;
    },
    [getColumnValue, sortDirection, sortField, sortFieldType]
  );

  const sortedData = useMemo(() => [...data].sort(comparisonFn), [comparisonFn, data]);

  const onSortByChange = useCallback(
    (newSortBy: string) => {
      setSortBy(newSortBy);

      if (sortByKey) {
        sessionStorage.setItem(sortByKey, newSortBy);
      }
    },
    [sortByKey]
  );

  return { sortedData, sortBy, setSortBy: onSortByChange };
}
