import { defineMessages } from 'react-intl';

// TODO: We should see if there are any performance issue with creating a giant object for all translations
// Alternative is to just use the corresponding id from `/lang/*.json`, and forget about defineMessages({})
export default defineMessages({
  atRiskAtRisk: {
    id: 'at-risk.at-risk',
    defaultMessage: 'Success Predictor',
  },
  atRiskHelpLink: {
    id: 'at-risk.help-link',
    defaultMessage: 'About the Allurion Success Predictor',
  },
  atRiskTooltipCheckmark: {
    id: 'at-risk.tooltip-checkmark',
    defaultMessage: 'Patient is on track to reach at least 10% TBWL at month 4.',
  },
  atRiskTooltipFlag: {
    id: 'at-risk.tooltip-flag',
    defaultMessage: 'Patient is not on track to reach 10% TBWL at month 4.',
  },
  atRiskModalTitle: {
    id: 'at-risk-modal.title',
    defaultMessage: 'Allurion Success Predictor',
  },
  atRiskModalContent1: {
    id: 'at-risk-modal.content1',
    defaultMessage: 'Our Success Predictor forecasts weight outcomes',
  },
  atRiskModalContent1Paragraph1: {
    id: 'at-risk-modal.content1.paragraph1',
    defaultMessage:
      "Allurion's Success Predictor uses artificial intelligence to help you quickly and easily identify whether your Allurion Program patient is likely to reach at least 10% total body weight loss (TBWL) at month four.",
  },
  atRiskModalContent1Paragraph2: {
    id: 'at-risk-modal.content1.paragraph2',
    defaultMessage: 'In order to maximize the accuracy of each prediction, your patient must:',
  },
  atRiskModalContent1Paragraph2Item1: {
    id: 'at-risk-modal.content1.paragraph2.item1',
    defaultMessage: 'Have weighed in at least four times',
  },
  atRiskModalContent1Paragraph2Item2: {
    id: 'at-risk-modal.content1.paragraph2.item2',
    defaultMessage:
      'Be around 1-2 months post-Balloon placement (the predictions start at day 20 and end at day 60 of the Program) ',
  },
  atRiskModalContent1Paragraph3: {
    id: 'at-risk-modal.content1.paragraph3',
    defaultMessage: 'These predictions:',
  },
  atRiskModalContent1Paragraph3Item1: {
    id: 'at-risk-modal.content1.paragraph3.item1',
    defaultMessage:
      'Are based on millions of high-quality data points from thousands of past patients',
  },
  atRiskModalContent1Paragraph3Item2: {
    id: 'at-risk-modal.content1.paragraph3.item2',
    defaultMessage: "Take into account each current patient's data patterns",
  },
  atRiskModalContent1Paragraph3Item3: {
    id: 'at-risk-modal.content1.paragraph3.item3',
    defaultMessage: 'Are recalculated every five days during the eligible time',
  },
  atRiskModalContent2: {
    id: 'at-risk-modal.content2',
    defaultMessage: 'The Success Predictor can guide your patient care and optimize outcomes',
  },
  atRiskModalContent2Paragraph1: {
    id: 'at-risk-modal.content2.paragraph1',
    defaultMessage: 'Patients with a red flag',
  },
  atRiskModalContent2Paragraph1Item1: {
    id: 'at-risk-modal.content2.paragraph1.item1',
    defaultMessage: 'May not reach 10% TBWL at month four, based on their current patterns',
  },
  atRiskModalContent2Paragraph1Item2: {
    id: 'at-risk-modal.content2.paragraph1.item2',
    defaultMessage: 'Might need some focus from you to set them on a path of success',
  },
  atRiskModalContent2Paragraph2: {
    id: 'at-risk-modal.content2.paragraph2',
    defaultMessage: 'Use the success predictor to:',
  },
  atRiskModalContent2Paragraph2Item1: {
    id: 'at-risk-modal.content2.paragraph2.item1',
    defaultMessage: '<b>Explore:</b> Review what you know so far about your patient',
  },
  atRiskModalContent2Paragraph2Item2: {
    id: 'at-risk-modal.content2.paragraph2.item2',
    defaultMessage: '<b>Enquire:</b> Reach out to your patient to learn more',
  },
  atRiskModalContent2Paragraph2Item3: {
    id: 'at-risk-modal.content2.paragraph2.item3',
    defaultMessage:
      "<b>Take action:</b> Do something differently, such as make changes to address your patient's individual needs and to help set them on the path to success",
  },
  atRiskModalContent2Paragraph3: {
    id: 'at-risk-modal.content2.paragraph3',
    defaultMessage: 'Patients with a green checkmark',
  },
  atRiskModalContent2Paragraph3Item1: {
    id: 'at-risk-modal.content2.paragraph3.item1',
    defaultMessage:
      'Are likely to be on track to reach 10% TBWL at month four, based on their current patterns',
  },
  atRiskModalContent2Paragraph3Item2: {
    id: 'at-risk-modal.content2.paragraph3.item2',
    defaultMessage:
      'Will benefit from continued support and positive feedback that all patients should ideally receive',
  },
  atRiskModalContent3: {
    id: 'at-risk-modal.content3',
    defaultMessage: 'Note:',
  },
  atRiskModalContent3Paragraph1: {
    id: 'at-risk-modal.content3.paragraph1',
    defaultMessage:
      'The Success Predictor is a tool to guide your patient management. No artificial intelligence model is perfect and it will never replace your own knowledge of your patients.',
  },
  atRiskModalContent3Paragraph2: {
    id: 'at-risk-modal.content3.paragraph2',
    defaultMessage:
      'The Success Predictor is only visible on Allurion Insights - it is not visible to your patients in the Allurion app. You should not discuss the Success Predictor with your patients. Telling patients they have been identified as not on track to reach their weight loss goals at month four may be highly demotivating.',
  },
  atRiskModalFooterLink: {
    id: 'at-risk-modal.footer-link',
    defaultMessage: 'For more training, please visit the <a>Allurion 4C Hub</a>.',
  },
  clinicOverview: {
    id: 'overview',
    defaultMessage: 'Overview',
  },
  clinicOverviewStartingWeight: {
    id: 'clinic-overview.starting-weight',
    defaultMessage: 'Starting weight',
  },
  clinicOverviewCurrentWeight: {
    id: 'clinic-overview.current-weight',
    defaultMessage: 'Current weight',
  },
  clinicOverviewEndingWeight: {
    id: 'clinic-overview.ending-weight',
    defaultMessage: 'Ending weight',
  },
  clinicOverviewTotalWeightLoss: {
    id: 'clinic-overview.total-weight-loss',
    defaultMessage: 'Total body weight loss',
  },
  clinicOverviewWeightLossFromLeanBodyMass: {
    id: 'clinic-overview.weight-loss-from-lean-body-mass',
    defaultMessage: 'Weight loss from lean body mass',
  },
  clinicOverviewWeightLossPercentageFromLeanBodyMassMessage: {
    id: 'clinic-overview.weight-loss-percentage-from-lean-body-mass-message',
    defaultMessage: 'of total weight loss',
  },
  clinicOverviewStartingBodyFat: {
    id: 'clinic-overview.starting-body-fat',
    defaultMessage: 'Starting body fat',
  },
  clinicOverviewCurrentBodyFat: {
    id: 'clinic-overview.current-body-fat',
    defaultMessage: 'Current body fat',
  },
  clinicOverviewWeightLossPercentage: {
    id: 'clinic-overview.weight-loss-percentage',
    defaultMessage: 'Total Body Weight Loss',
  },
  clinicOverviewStartingBmi: {
    id: 'clinic-overview.starting-bmi',
    defaultMessage: 'Starting BMI',
  },
  clinicOverviewCurrentBmi: {
    id: 'clinic-overview.current-bmi',
    defaultMessage: 'Current BMI',
  },
  clinicOverviewEndingBmi: {
    id: 'clinic-overview.ending-bmi',
    defaultMessage: 'Ending BMI',
  },
  clinicOverviewWeeksInProgram: {
    id: 'clinic-overview.weeks-in-program',
    defaultMessage: 'Weeks in treatment',
  },
  clinicOverviewWeeksOneWeighIn: {
    id: 'clinic-overview.weeks-atleast-one-weighin',
    defaultMessage: 'Weeks with at least 1 weigh-in',
  },
  clinicOverviewTBWL: {
    id: 'TBWL',
    defaultMessage: 'Total Body Weight Loss (TBWL %)',
  },
  patientViewProfile: {
    id: 'patient-view-profile',
    defaultMessage: 'View Profile',
  },
  chatErrorConnection: {
    id: 'chat-error.not-connected',
    defaultMessage: 'There has been a connection error. Please refresh the page.',
  },
  treatmentHistory: {
    id: 'patient-page.overview.treatment-history-title',
    defaultMessage: 'Treatment History',
  },
  progressOverview: {
    id: 'patient-page.overview.progress-overview-title',
    defaultMessage: 'Progress Overview',
  },
  totalBodyWeightLoss: {
    id: 'patient-page.overview.total-body-weight-loss-title',
    defaultMessage: 'Total Body Weight Loss (TBWL %)',
  },
  providers: {
    id: 'patient-page.overview.providers-title',
    defaultMessage: 'Providers',
  },
  personalInformation: {
    id: 'patient-page.overview.personal-information-title',
    defaultMessage: 'Personal Information',
  },
  overviewNotesTooltip: {
    id: 'patient-page.overview.notes-tooltip',
    defaultMessage: 'Learn more about our overview metrics',
  },
});
