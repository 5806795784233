import { isNull, isUndefined } from '@allurion/utils';

export const HeightUnits = {
  Cm: 'cm',
  In: 'in',
} as const;
export type HeightUnit = (typeof HeightUnits)[keyof typeof HeightUnits];

export function isHeightUnit(unit: string): unit is HeightUnit {
  return Object.values(HeightUnits).includes(unit as HeightUnit);
}

export function formatHeight(value: string | number | null | undefined, unit: HeightUnit) {
  if (isUndefined(value) || isNull(value)) {
    return '';
  }

  const numericValue = Number(value);

  switch (unit) {
    case HeightUnits.In:
      return cmToHeightInches(numericValue);

    default:
      return `${value} cm`;
  }
}

export function cmToHeightInches(numInCm: number) {
  const inches = numInCm / 2.54;
  const feet = Math.floor(inches / 12);
  const finalInches = Math.round(inches % 12);

  return `${feet}'${finalInches}"`;
}
