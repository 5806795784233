import { FormGroup, TextInput } from '@allurion/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { TrackedButton } from 'src/analytics/TrackedUI';
import globalMessages from 'src/messages/global.messages';

import messages from './reset-password-messages';

type Props = {
  onSubmit: (values: FormValues) => void;
};

type FormValues = {
  email: string;
};

export function ResetPasswordForm({ onSubmit }: Props) {
  const intl = useIntl();

  const ResetPasswordForm = Yup.object()
    .shape({
      email: Yup.string()
        .required(intl.formatMessage(globalMessages.required))
        .email(intl.formatMessage(globalMessages.notValidEmail)),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(ResetPasswordForm),
  });

  return (
    <>
      <Text>{intl.formatMessage(globalMessages.resetPasswordEmailText)}</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label={intl.formatMessage(messages.email)} error={errors.email?.message}>
          <TextInput
            type="email"
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            {...register('email')}
          />
        </FormGroup>
        <Controls>
          <TrackedButton
            label={intl.formatMessage(globalMessages.resetPassword)}
            type="submit"
            variant="secondary"
            trackLabel="submit-reset-password"
          />
        </Controls>
      </Form>
    </>
  );
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;

  label {
    color: #fff;
    margin-bottom: 15px;
    font-size: 20px;
    display: inline-block;
    font-family: GT-America-Standard, sans-serif;
  }

  input {
    resize: none;
    width: 100%;
    font-family: GT-America-Standard, sans-serif;
    font-size: 16px;
    height: 100%;
    padding: 18px 14px;
    border-radius: 2px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255) !important;
  }

  input + span {
    display: flex;
    -webkit-box-pack: start;
    justify-content: start;
    text-align: left;
    color: rgb(166, 101, 101);
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 12px;
    padding-left: 0px;
    transition:
      height 0.5s ease 0s,
      opacity 0.3s ease 0.2s;
    height: 0px;
    font-family: GT-America-Standard, sans-serif;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 25px;

  button {
    width: 100%;
  }
`;

const Text = styled.p`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 320px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
`;
