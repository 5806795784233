import { FormGroup, TextInput } from '@allurion/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { PasswordPopup } from 'src/components/PasswordPopup/PasswordPopup';
import { MyLink as Link } from 'src/components/ui/Link';
import globalMessages from 'src/messages/global.messages';

import messages from './validate-email-code-messages';

type FormValues = {
  code: string;
  password: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  resendEmail: () => void;
};

export function ValidateEmailCodeForm({ onSubmit, resendEmail }: Props) {
  const intl = useIntl();

  const ValidateEmailCodeFormSchema = Yup.object()
    .shape({
      code: Yup.string().required(intl.formatMessage(globalMessages.required)),
      password: Yup.string()
        .required(intl.formatMessage(globalMessages.required))
        .min(8, intl.formatMessage(globalMessages.minChars))
        .matches(/[a-z]/, intl.formatMessage(globalMessages.oneLower))
        .matches(/[A-Z]/, intl.formatMessage(globalMessages.oneCapital))
        .matches(/[0-9]/, intl.formatMessage(globalMessages.oneNumber))
        .matches(/[!@#$%^&*()_+{}:;'"/\\]/, intl.formatMessage(globalMessages.oneSpecialChar)),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(ValidateEmailCodeFormSchema),
  });

  return (
    <>
      <Text>{intl.formatMessage(globalMessages.resetPasswordVerificationCodeText)}</Text>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormGroup
          label={intl.formatMessage(messages.yourVerficationCode)}
          error={errors.code?.message}
        >
          <TextInput
            type="text"
            placeholder={intl.formatMessage(messages.verficationCode)}
            autoComplete="off"
            {...register('code')}
          />
        </FormGroup>
        <FormGroup
          label={
            <span style={{ display: 'flex', gap: '10px' }}>
              {`${intl.formatMessage(messages.newPassword)} `}
              <PasswordPopup />
            </span>
          }
          error={errors.password?.message}
        >
          <TextInput
            type="password"
            placeholder={intl.formatMessage(messages.password)}
            autoComplete="new-password"
            {...register('password')}
          />
        </FormGroup>

        <Controls>
          <TrackedButton
            type="submit"
            label={intl.formatMessage(messages.submit)}
            variant="secondary"
            trackLabel="submit-validate-email-code"
          />
          <StyledLink
            to="#!"
            onClick={(evt: any) => {
              evt.preventDefault();
              resendEmail();
            }}
          >
            {intl.formatMessage(messages.resendVerfification)}
          </StyledLink>
          <EmailWrapper>
            {`${intl.formatMessage(messages.email)} `}
            <StyledAnchor href="mailto:help@allurion.com" target="_blank">
              help@allurion.com
            </StyledAnchor>
          </EmailWrapper>
        </Controls>
      </Form>
    </>
  );
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;

  label {
    color: #fff;
    margin-bottom: 15px;
    font-size: 20px;
    display: inline-block;
    font-family: GT-America-Standard, sans-serif;
  }

  input {
    resize: none;
    width: 100%;
    font-family: GT-America-Standard, sans-serif;
    font-size: 16px;
    height: 100%;
    padding: 18px 14px;
    border-radius: 2px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255) !important;
  }

  input + span {
    display: flex;
    -webkit-box-pack: start;
    justify-content: start;
    text-align: left;
    color: rgb(166, 101, 101);
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 12px;
    padding-left: 0px;
    transition:
      height 0.5s ease 0s,
      opacity 0.3s ease 0.2s;
    height: 0px;
    font-family: GT-America-Standard, sans-serif;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 25px;

  a {
    border-bottom: 1px solid #fff;
  }

  button {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 40px;
`;

const StyledAnchor = styled.a`
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.75;
  }
`;

const EmailWrapper = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 16px;
  margin-top: 70px;
`;

const Text = styled.p`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 320px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
`;
