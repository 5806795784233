import { defineMessages, MessageDescriptor } from 'react-intl';

const messages = defineMessages({
  categoryLabel: { id: 'notes.category.label', defaultMessage: 'Filter notes by category' },
  categorySelect: { id: 'notes.category.select', defaultMessage: 'Select category' },
  categoryAll: { id: 'notes.category.all', defaultMessage: 'All categories' },
  searchPlaceholder: { id: 'notes.search.placeholder', defaultMessage: 'Search' },
  newNote: { id: 'notes.search.newNote', defaultMessage: 'NEW NOTE' },
  done: { id: 'notes.done', defaultMessage: 'DONE' },
  save: { id: 'notes.save', defaultMessage: 'SAVE' },
  discard: { id: 'notes.discard', defaultMessage: 'DISCARD' },
  cancel: { id: 'notes.cancel', defaultMessage: 'CANCEL' },
  goBack: { id: 'notes.goBack', defaultMessage: 'GO BACK' },
  delete: { id: 'notes.delete', defaultMessage: 'DELETE' },
  ok: { id: 'notes.ok', defaultMessage: 'OK' },
  logout: { id: 'notes.logout', defaultMessage: 'Logout' },
  newNoteTitle: { id: 'notes.newNoteTitle', defaultMessage: 'New Note' },
  enterText: { id: 'notes.enterText', defaultMessage: 'Enter text before saving.' },
  selectTopic: { id: 'notes.selectTopic', defaultMessage: 'Select topic before saving.' },
  saveNoteModalText: {
    id: 'notes.modal.saveNote',
    defaultMessage: 'Do you want to save your note?',
  },
  deleteNoteModalText: {
    id: 'notes.modal.deleteNote',
    defaultMessage: 'Do you want to delete your note?',
  },
  cancelNoteModalText: {
    id: 'notes.modal.cancelNote',
    defaultMessage: 'Do you want to delete your note?',
  },
  saveNoteSuccess: { id: 'notes.modal.saveSuccess', defaultMessage: 'Note successfully saved.' },
  saveNoteFailure: { id: 'notes.modal.saveFailure', defaultMessage: 'Failed to save note.' },
  deleteNoteSuccess: {
    id: 'notes.modal.deleteSuccess',
    defaultMessage: 'Note successfully deleted.',
  },
  deleteNoteFailure: { id: 'notes.modal.deleteFailure', defaultMessage: 'Failed to delete note.' },
  newNotePlaceholder: {
    id: 'notes.modal.newNotePlaceholder',
    defaultMessage: 'Select a category for your note.',
  },
  videoConsult: { id: 'notes.videoConsult', defaultMessage: 'Video Consult' },
  videoConsultMessage: {
    id: 'notes.videoConsultMessage',
    defaultMessage: 'Video consult between {initiator} and {opponent}',
  },

  // These messages correspond to a database topic
  noteTopicGoalSetting: {
    id: 'notes.topicGoalSetting',
    defaultMessage: 'Goal setting / action planning',
  },
  noteTopicCheckIn: { id: 'notes.topicCheckIn', defaultMessage: 'Check-in' },
  noteTopicPhysicalActivity: {
    id: 'notes.topicPhysicalActivity',
    defaultMessage: 'Physical activity',
  },
  noteTopicNutrition: { id: 'notes.topicNutrition', defaultMessage: 'Nutrition' },
  noteTopicWRC: { id: 'notes.topicWRC', defaultMessage: 'WLAs / Resources / Content' },
  noteTopicCommunity: { id: 'notes.topicCommunity', defaultMessage: 'Community' },
  noteTopicSymptomManagement: {
    id: 'notes.topic.SymptomManagement',
    defaultMessage: 'Symptom management',
  },
  noteTopicPsychologicalTechniques: {
    id: 'notes.topicPsychologicalTechniques',
    defaultMessage: 'Psychological techniques',
  },
  noteTopicAdverseEvent: {
    id: 'notes.topicAdverseEvent',
    defaultMessage: 'Adverse event / Referral',
  },
  noteTopicOther: { id: 'notes.topicOther', defaultMessage: 'Other' },
  emptyStateTitle: {
    id: 'notes.emptyStateTitle',
    defaultMessage: 'Notes will appear here',
  },
  emptyStateSubtitle: {
    id: 'notes.emptyStateDescription',
    defaultMessage:
      'This is a space for any type of note related to this patient. Symptoms, nutrition, check-in consults, etc. Add a note to start.',
  },
  nothingFoundTitle: {
    id: 'notes.nothingFoundTitle',
    defaultMessage: 'Nothing found',
  },
  nothingFoundSubtitle: {
    id: 'notes.nothingFoundSubtitle',
    defaultMessage: 'No notes were found. Check your search or try a different category.',
  },
});

interface INoteTopicMessage {
  [key: number]: MessageDescriptor;
}

export const noteTopicMessages: INoteTopicMessage = {
  0: messages.noteTopicGoalSetting,
  1: messages.noteTopicCheckIn,
  2: messages.noteTopicPhysicalActivity,
  3: messages.noteTopicNutrition,
  4: messages.noteTopicWRC,
  5: messages.noteTopicCommunity,
  6: messages.noteTopicSymptomManagement,
  7: messages.noteTopicPsychologicalTechniques,
  8: messages.noteTopicAdverseEvent,
  9: messages.noteTopicOther,
};

export default messages;
