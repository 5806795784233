import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'add-patient.header',
    defaultMessage: 'Patient added',
  },
  title: {
    id: 'add-patient.title',
    defaultMessage: 'Add Patient',
  },
  personalInformationTitle: {
    id: 'add-patient.personal-information.title',
    defaultMessage: 'Personal Information',
  },
  treatmentInformationTitle: {
    id: 'add-patient.treatment-information.title',
    defaultMessage: 'Treatment Information',
  },
  firstName: {
    id: 'add-patient.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'add-patient.lastName',
    defaultMessage: 'Last Name',
  },
  name: {
    id: 'add-patient.name',
    defaultMessage: 'Name',
  },
  email: {
    id: 'add-patient.email',
    defaultMessage: 'Email Address',
  },
  treatment: {
    id: 'add-patient.treatment',
    defaultMessage: 'Treatment',
  },
  startDate: {
    id: 'add-patient.startDate',
    defaultMessage: 'Program Start Date',
  },
  clinic: {
    id: 'add-patient.clinic',
    defaultMessage: 'Clinic',
  },
  cancel: {
    id: 'add-patient.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'add-patient.submit',
    defaultMessage: 'Review and Confirm',
  },
  balloon: {
    id: 'add-patient.balloon',
    defaultMessage: 'Allurion Balloon',
  },
  other: {
    id: 'add-patient.other',
    defaultMessage: 'Other',
  },
  reviewDetails: {
    id: 'add-patient.reviewDetails',
    defaultMessage: 'Please review patient details',
  },
  makeChanges: {
    id: 'add-patient.makeChanges',
    defaultMessage: 'Make Changes',
  },
  providerAccess: {
    id: 'add-patient.providerAccess',
    defaultMessage: 'Provider Access',
  },
  providerAccessDescription: {
    id: 'add-patient.providerAccessDescription',
    defaultMessage: 'Clinic staff with access to this patient',
  },
  providerAccessFieldLabel: {
    id: 'add-patient.providerAccessFieldLabel',
    defaultMessage: 'Select providers',
  },
  confirm: {
    id: 'add-patient.confirm',
    defaultMessage: 'Add Patient',
  },
  added: {
    id: 'add-patient.success',
    defaultMessage: '{name} has been added.',
  },
  invitation: {
    id: 'add-patient.invitation',
    defaultMessage: 'An invitation email has been sent to {email}.',
  },
  instructions: {
    id: 'add-patient.instructions',
    defaultMessage: '{name} needs to open the email and finish setting up the account.',
  },
  expiration: {
    id: 'add-patient.expiration',
    defaultMessage: 'This invitation will expire after 48 hours.',
  },
  emailAlreadyInUseMessage: {
    id: 'add-patient.email-already-in-use',
    defaultMessage: 'This email address is already in use.',
  },
  done: {
    id: 'add-patient.done',
    defaultMessage: 'Done',
  },
  viewPatientBtn: {
    id: 'add-patient.view-patient-btn',
    defaultMessage: 'View patient',
  },
  inviteAnotherPatientBtn: {
    id: 'add-patient.invite-another-patient-btn',
    defaultMessage: 'Add another patient',
  },
  backToClinicBtn: {
    id: 'add-patient.back-to-clinic-btn',
    defaultMessage: 'Back to patients',
  },
});
