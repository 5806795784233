import { ClinicPatient } from '@allurion/domain';
import { FIVE_MINUTES_IN_MS, ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';

export const ACTIVE_PATIENTS = (clinicId: string) => `/clinics/${clinicId}/active-patients`;

export function useClinicPatients(
  clinicId: string,
  {
    showAll,
    patientFilter,
    provider,
    suspense = true,
  }: {
    showAll?: boolean;
    patientFilter?: string;
    suspense?: boolean;
    provider?: string;
  } = {}
) {
  const query = {
    search: patientFilter || '',
    showAll,
    provider,
  };

  const url = buildUrl(ACTIVE_PATIENTS(clinicId.toString()), query);
  const { data, error, isValidating, isLoading, mutate } = useSWR<ClinicPatient[]>(
    url,
    (_url) =>
      InsightsApi.get<{ activePatients: ClinicPatient[] }>(_url).then((res) => res.activePatients),
    {
      suspense,
      dedupingInterval: ONE_MINUTE_IN_MS,
      refreshInterval: FIVE_MINUTES_IN_MS,
    }
  );

  return {
    activePatients: (data ?? []) as ClinicPatient[],
    error,
    isLoading: isValidating || isLoading,
    reload: mutate,
  };
}
