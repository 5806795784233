import { PropsWithChildren, useEffect, useState, ReactNode } from 'react';
import { usePopper } from 'react-popper';

import styles from './Tooltip.module.scss';
import { classNames } from '../../utils';

type Props = {
  children: ReactNode;
  anchorEl: HTMLElement | null;
  variant?: 'dark' | 'light';
  placement?: 'top' | 'bottom' | 'left' | 'right';
  enableArrow?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};

type TooltipProps = PropsWithChildren<{
  text: string;
  enableArrow?: boolean;
  variant?: 'dark' | 'light';
  placement?: 'top' | 'bottom' | 'left' | 'right';
}>;

export function Tooltip({
  text,
  children,
  variant = 'dark',
  placement = 'right',
  enableArrow = true,
}: TooltipProps) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  return text ? (
    <>
      <div ref={setRef} onClick={() => setOpen(false)} className={styles.tooltipWrapper}>
        {children}
      </div>

      <TooltipContent
        anchorEl={ref}
        variant={variant}
        placement={placement}
        enableArrow={enableArrow}
        open={open}
        setOpen={setOpen}
      >
        {text}
      </TooltipContent>
    </>
  ) : (
    children
  );
}

export function TooltipContent({
  children,
  anchorEl,
  variant,
  enableArrow,
  placement,
  open,
  setOpen,
}: Props) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles } = usePopper(anchorEl, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      { name: 'preventOverflow', enabled: true },
    ],
  });

  useEffect(() => {
    if (!anchorEl) return;

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    anchorEl.addEventListener('mouseenter', handleTooltipOpen);
    anchorEl.addEventListener('mouseleave', handleTooltipClose);

    return () => {
      anchorEl.removeEventListener('mouseenter', handleTooltipOpen);
      anchorEl.removeEventListener('mouseleave', handleTooltipClose);
    };
  }, [anchorEl]);

  return (
    <>
      {open && (
        <div
          className={classNames(
            styles['tooltip'],
            styles[`tooltip__${placement}`],
            styles[`tooltip__${variant}`]
          )}
          ref={setPopperElement}
          style={popperStyles.popper}
        >
          {enableArrow && <div ref={setArrowElement} className={styles['arrow']} />}
          <div className={styles['content']}>{children}</div>
        </div>
      )}
    </>
  );
}
