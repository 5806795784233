export function WhiteQuestion() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-cy="question-circle"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M8.90066 15.762C8.61733 15.49 8.47566 15.1443 8.47566 14.725C8.47566 14.3057 8.61733 13.96 8.90066 13.688C9.18399 13.4047 9.53533 13.263 9.95466 13.263C10.374 13.263 10.7253 13.4047 11.0087 13.688C11.3033 13.96 11.4507 14.3057 11.4507 14.725C11.4507 15.133 11.3033 15.4787 11.0087 15.762C10.7253 16.034 10.374 16.17 9.95466 16.17C9.53533 16.17 9.18399 16.034 8.90066 15.762ZM8.90066 7.007L6.75866 6.446C6.91733 5.732 7.29699 5.137 7.89766 4.661C8.49833 4.17367 9.24066 3.93 10.1247 3.93C11.088 3.93 11.8983 4.22467 12.5557 4.814C13.2243 5.392 13.5587 6.11733 13.5587 6.99C13.5587 7.45467 13.4623 7.87967 13.2697 8.265C13.0883 8.639 12.8617 8.95067 12.5897 9.2C12.329 9.438 12.0627 9.659 11.7907 9.863C11.53 10.0557 11.3033 10.2483 11.1107 10.441C10.9293 10.6337 10.8387 10.8207 10.8387 11.002C10.8387 11.2173 10.935 11.4213 11.1277 11.614L9.41066 12.804C8.88933 12.192 8.62866 11.5743 8.62866 10.951C8.62866 10.543 8.71933 10.1747 8.90066 9.846C9.09333 9.506 9.31999 9.22267 9.58066 8.996C9.85266 8.76933 10.119 8.55967 10.3797 8.367C10.6517 8.163 10.8783 7.94767 11.0597 7.721C11.2523 7.49433 11.3487 7.262 11.3487 7.024C11.3487 6.70667 11.2353 6.45167 11.0087 6.259C10.782 6.06633 10.4873 5.97 10.1247 5.97C9.77333 5.97 9.48433 6.07767 9.25766 6.293C9.04233 6.497 8.92333 6.735 8.90066 7.007Z"
        fill="#131730"
      />
    </svg>
  );
}
