import { ForwardedRef, forwardRef } from 'react';
import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './SelectInput.module.scss';
import { classNames } from '../../utils';

interface SelectInputProps<T>
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size' | 'type'> {
  size?: 'sm' | 'md' | 'lg';
  invalid?: boolean;
  options: {
    label: string;
    value: T;
  }[];
  className?: string;
  placeholder?: string;
}

export const SelectInput = forwardRef(function SelectInput<T extends number | string>(
  { options, className, size = 'sm', invalid, placeholder, ...props }: SelectInputProps<T>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  return (
    <div
      className={classNames(
        styles['select-input-container'],
        textInputStyles['text-input'],
        props.disabled ? textInputStyles['text-input-disabled'] : '',
        invalid ? textInputStyles['text-input-invalid'] : '',
        textInputStyles[`text-input-${size}`],
        className
      )}
    >
      <select {...props} className={styles['select-input']} ref={ref}>
        {placeholder && (
          <option key="no-value-selected" value="" className={styles[`select-input-placeholder`]}>
            {placeholder}
          </option>
        )}
        {options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
});
