import { classNames } from '@allurion/ui';

import styles from './ValueWithArrow.module.scss';

export function ValueWithArrow({ value, label }: { value: number; label: string }) {
  return (
    <span className={classNames(styles.cellValue, value < 0 && styles['text-danger'])}>
      {value !== 0 ? (value > 0 ? '-' : '+') : ''}
      &nbsp;
      {label}
    </span>
  );
}
