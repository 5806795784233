import {
  ClinicPatient,
  didInstalledMobileApp,
  hoistPatientsWithNoTreatments,
  isRiskAnalyisEnabled,
} from '@allurion/domain';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Pagination } from 'src/components/Pagination';
import { useClinicPatients } from 'src/hooks/useClinicPatients';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useDevice } from 'src/hooks/useDevice';
import { usePagination } from 'src/hooks/usePagination';
import { useTableSort } from 'src/ui/useTableSort';

import { ClinicPatientsActions } from './ClinicPatientsActions';
import { ClinicPatientsCards } from './ClinicPatientsCards';
import { ClinicPatientsEmptyState } from './ClinicPatientsEmptyState';
import { PatientFilter } from './ClinicPatientsFilter';
import { ClinicPatientsTable } from './ClinicPatientsTable';

import styles from './ClinicPatients.module.scss';

type Props = {
  clinicId: string;
  filters: PatientFilter;
  onFilterChange: (filters: PatientFilter) => void;
};

export function ClinicPatients({ clinicId, filters, onFilterChange }: Props) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const { settings } = useClinicSettings(clinicId);
  const viewFilter = filters.view;
  const statusFilter = filters.status;
  const treatmentFilter = filters.treatment;
  const providerFilter = filters.provider;
  const { isLoading, activePatients } = useClinicPatients(clinicId, {
    showAll: viewFilter === 'all',
    provider: providerFilter,
  });
  const { page, setPage, pageSize, setPageSize, defaultPageSize, paginateData, getLastPage } =
    usePagination(`clinic-patients-${clinicId}`);

  const { isMobile } = useDevice();

  const filteredPatients = useMemo(() => {
    return activePatients.filter((patient) => {
      let meetsFilter = false;

      switch (statusFilter) {
        case 'active':
          if (
            patient.active_patient &&
            didInstalledMobileApp(patient) &&
            patient.latest_treatment_id
          ) {
            meetsFilter = true;
          }
          break;

        case 'inactive':
          if (!patient.active_patient && didInstalledMobileApp(patient)) {
            meetsFilter = true;
          }
          break;

        case 'invited':
          if (!didInstalledMobileApp(patient)) {
            meetsFilter = true;
          }
          break;

        case 'incomplete':
          if (!patient.latest_treatment_id) {
            meetsFilter = true;
          }
          break;

        default:
          meetsFilter = true;
          break;
      }

      if (treatmentFilter) {
        meetsFilter = meetsFilter && patient.latest_treatment_id === treatmentFilter;
      }

      return (
        meetsFilter &&
        (patient.name?.toLowerCase().includes(search?.toLowerCase() ?? '') ||
          patient.lastname?.toLowerCase().includes(search?.toLowerCase() ?? ''))
      );
    });
  }, [activePatients, search, statusFilter, treatmentFilter]);

  const { sortedData, sortBy, setSortBy } = useTableSort<ClinicPatient>(
    filteredPatients,
    '-latest_treatment_start_date',
    {
      perc_weight_loss_since_placement: 'number',
      weight_loss_since_placement: 'number',
      current_weight: 'number',
      age: 'number',
      at_risk_score: 'number',
      current_bmi: 'number',
      latest_treatment_id: 'number',
      latest_treatment_category_id: 'number',
    },
    `clinic-patients-${clinicId}`
  );

  const sortedPatients = hoistPatientsWithNoTreatments(sortedData);

  const paginatedPatients = paginateData(sortedPatients);
  const lastPage = getLastPage(sortedPatients);

  const atRiskEnabled = isRiskAnalyisEnabled(settings);

  const noAvailablePatients = activePatients.length === 0 && !isLoading;
  const emptySearch = search.length > 0 && filteredPatients.length === 0;
  const showEmptyState = noAvailablePatients || emptySearch;

  return (
    <div className={styles.container}>
      <ClinicPatientsActions
        isLoading={isLoading}
        clinicId={clinicId}
        noAvailablePatients={noAvailablePatients}
        showEmptyState={showEmptyState}
        sortBy={sortBy}
        setSortBy={setSortBy}
        page={page}
        setPage={setPage}
        onSearch={onSearch}
        activeFilter={filters}
        onFilterChange={updateFilters}
      />

      {showEmptyState ? (
        <ClinicPatientsEmptyState
          noAvailablePatients={noAvailablePatients}
          emptySearch={emptySearch}
          settings={settings}
          clinicId={clinicId}
        />
      ) : (
        <>
          {isMobile ? (
            <ClinicPatientsCards
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
            />
          ) : (
            <ClinicPatientsTable
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}
          <div className={styles.pagination}>
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              defaultPageSize={defaultPageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              pageSizeLabel={intl.formatMessage({
                id: 'patients.page-size-label',
                defaultMessage: 'Patients per page',
              })}
            />
          </div>
        </>
      )}
    </div>
  );

  function onSearch(value: string) {
    setSearch(value);
    //reset page to 1 when searching
    if (page !== 1) {
      setPage(1);
    }
  }

  function updateFilters(filters: PatientFilter) {
    onFilterChange(filters);

    //reset page to 1 when searching
    if (page !== 1) {
      setPage(1);
    }
  }
}
