import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { VIDEO_SIZE } from 'src/utils/constants';

import messages from './video-call.messages';

type Props = {
  patientId: string;
};

export function VideoCallEndScreen({ patientId }: Props) {
  const intl = useIntl();
  const { patient } = usePatientProfile(patientId);

  const patientName = `${patient?.name} ${patient?.lastname}`;
  const leftCallMessage = patientName + intl.formatMessage(messages.leftCall);
  const lostSignalMessage = patientName + intl.formatMessage(messages.lostSignal);
  const rejoinCallMessage = patientName + intl.formatMessage(messages.rejoinCall);

  return (
    <EndCallScreenWrapper>
      <LeftCall>{leftCallMessage}</LeftCall>
      <LostSignal>{lostSignalMessage}</LostSignal>
      <RejoinCall>{rejoinCallMessage}</RejoinCall>
    </EndCallScreenWrapper>
  );
}

const EndCallScreenWrapper = styled.div<any>`
  width: ${VIDEO_SIZE.width}px;
  height: ${VIDEO_SIZE.height}px;
  background-color: ${({ theme }) => theme.colors.Silver};
  margin: 0 auto;
  position: relative;
  text-align: left;
  color: ${({ theme }) => theme.colors.Primary};
`;

const LeftCall = styled.p`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.2199999988079071px;
  width: 80%;
`;

const LostSignal = styled.p`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 60%;
  font-size: 16px;
  width: 80%;
`;

const RejoinCall = styled.p`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 70%;
  font-size: 16px;
  width: 80%;
`;
