import { isExportDataEnabled } from '@allurion/domain';
import { CloseIcon, FilterIcon, InputGroup, SearchIcon, Tooltip } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton, TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { exportClinicData } from 'src/api/ExportApi';
import { DownloadDataBtn, useDownloadData } from 'src/components/DownloadDataBtn';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { Sidebar } from 'src/components/ui/Sidebar';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useDebounceCallback } from 'src/hooks/useDebounceCallback';
import { useDevice } from 'src/hooks/useDevice';

import messages from './active-patients-table.messages';
import { ClinicPatientsFilter, PatientFilter } from './ClinicPatientsFilter';

import styles from './ClinicPatients.module.scss';

type Props = {
  clinicId: string;
  noAvailablePatients: boolean;
  showEmptyState: boolean;
  isLoading: boolean;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  page: number;
  setPage: (page: number) => void;
  onSearch: (search: string) => void;
  onFilterChange: (filter: PatientFilter) => void;
  activeFilter: PatientFilter;
};

export function ClinicPatientsActions({
  isLoading,
  clinicId,
  noAvailablePatients,
  showEmptyState,
  sortBy,
  setSortBy,
  onSearch,
  activeFilter,
  onFilterChange,
}: Props) {
  const intl = useIntl();
  const exportData = async () => exportClinicData(clinicId, navigator.language);
  const { onExportData, isExportingData } = useDownloadData({ exportData });
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const { settings } = useClinicSettings(clinicId);
  const { isAdmin } = useCurrentProvider();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { isMobile } = useDevice();
  const hideDownloadDataBtnOnMobile = isMobile && showSearchInput;

  const showDownloadDataBtn =
    isAdmin && isExportDataEnabled(settings) && !hideDownloadDataBtnOnMobile && !showEmptyState;
  const showSortByBtn = !showEmptyState;

  const debounceSearch = useDebounceCallback(handleOnSearch, 1500);

  if (noAvailablePatients) {
    return;
  }

  return (
    <div className={styles.header}>
      <div className={styles.actionIcons}>
        {showSearchInput ? (
          <div className={styles.patientSearchInputContainer}>
            <InputGroup
              prefix={<SearchIcon />}
              textInput={{
                type: 'text',
                placeholder: intl.formatMessage(messages.searchPlaceholder),
                onChange: debounceSearch,
              }}
            />
            <TrackedIconButton
              icon={<CloseIcon />}
              variant="icon"
              onClick={() => {
                setShowSearchInput(false);
                onSearch('');
              }}
              trackLabel="close-patient-search-input"
            />
          </div>
        ) : (
          <TrackedIconButton
            icon={<SearchIcon />}
            variant="icon"
            size="sm"
            onClick={() => setShowSearchInput(true)}
            trackLabel="open-patient-search-input"
          />
        )}
      </div>

      <div className={styles.actionIcons}>
        <div className={styles.loader}>{isLoading && <InlineLoader size="16px" />}</div>
        {showDownloadDataBtn && (
          <Tooltip
            text={intl.formatMessage({
              id: 'clinic-patients.export-data-tooltip',
              defaultMessage: 'Export data',
            })}
            placement="bottom"
          >
            <DownloadDataBtn exportData={onExportData} />
          </Tooltip>
        )}

        <Tooltip
          text={intl.formatMessage({
            id: 'clinic-patients.filter',
            defaultMessage: 'Filter',
          })}
          placement="bottom"
        >
          <TrackedIconButton
            icon={<FilterIcon />}
            variant="icon"
            size="sm"
            onClick={() => setIsSidebarOpen(true)}
            trackLabel="open-patient-filter"
          />
        </Tooltip>

        {showSortByBtn && (
          <TrackedSortByMenu
            selectedOption={sortBy}
            trackLabel="clinic-patients-sort-by"
            onChange={onSortByUpdate}
            size="sm"
            tooltipText={intl.formatMessage({
              id: 'clinic-patients.sort-by-tooltip',
              defaultMessage: 'Sort patients',
            })}
            options={[
              {
                value: 'name',
                label: intl.formatMessage(messages.firstName),
              },
              {
                value: 'at_risk_score',
                label: intl.formatMessage(messages.atRisk),
              },
              {
                value: 'age',
                label: intl.formatMessage(messages.age),
              },
              {
                value: 'current_weight',
                label: intl.formatMessage(messages.currentWeight),
              },
              {
                value: 'current_bmi',
                label: 'BMI',
              },
              {
                value: 'weight_loss_since_placement',
                label: intl.formatMessage(messages.weightChange),
              },
              {
                value: 'perc_weight_loss_since_placement',
                label: 'TBWL',
              },
              {
                value: 'last_weigh_in',
                label: intl.formatMessage(messages.lastWeighInDate),
              },
              {
                value: 'latest_treatment_start_date',
                label: intl.formatMessage(messages.daysInTreatment),
              },
              {
                value: 'latest_treatment_id',
                label: intl.formatMessage(messages.treatmentType),
              },
              {
                value: 'latest_treatment_category_id',
                label: intl.formatMessage(messages.treatmentCategory),
              },
            ]}
          />
        )}
      </div>
      {isExportingData && <Loader isLoading />}
      <Sidebar isOpen={isSidebarOpen} offcanvas>
        <ClinicPatientsFilter
          activeFilter={activeFilter}
          onClose={() => setIsSidebarOpen(false)}
          onFilterChange={onFilterChange}
        />
      </Sidebar>
    </div>
  );

  function onSortByUpdate(value: string) {
    setSortBy(value);
  }

  function handleOnSearch(e: React.ChangeEvent<HTMLInputElement>) {
    onSearch(e.target.value);
  }
}
