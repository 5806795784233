export function roundUpHalf(value: number, decimals = 1) {
  const factor = 10 ** decimals;

  return Math.ceil(value * factor) / factor;
}

export function rootMeanSquare(samples: Uint8Array) {
  const sumSq = samples.reduce((oldSumSq, sample) => oldSumSq + sample * sample, 0);

  return Math.sqrt(sumSq / samples.length);
}
