export const SUPER_ADMIN_ROLE_ID = '1';
export const ADMIN_ROLE_ID = '2';
export const STAFF_ROLE_ID = '3';
export const COACH_ROLE_ID = '4';

export const isSuperAdmin = (roleId: string) => roleId === SUPER_ADMIN_ROLE_ID;
export const isAdmin = (roleId: string | null) => roleId === ADMIN_ROLE_ID;
export const isStaff = (roleId: string) => roleId === STAFF_ROLE_ID;
export const isCoach = (roleId: string) => roleId === COACH_ROLE_ID;

// NOTE: Gets initials from a name
// if name +3 words, this will get the first and very last name
// will keep initials into 2 digits, and we won't need to re-adjust for +3 intials
export function getInitials(s: string) {
  return s
    ?.match(/(\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('')
    .toUpperCase();
}
