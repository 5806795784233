import { defineMessages } from 'react-intl';

export default defineMessages({
  noPatientsFoundTitle: {
    id: 'clinic-patients.no-patients-found-title',
    defaultMessage: 'Nothing found',
  },
  noPatientsFoundSubtitle: {
    id: 'clinic-patients.no-patients-found-subtitle',
    defaultMessage: 'No patients were found, check your search or try a different name.',
  },
  noPatientsAvailableTitle: {
    id: 'clinic-patients.no-patients-available-title',
    defaultMessage: 'Your patients will appear here',
  },
  noPatientsAvailableForAdminWithPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-admin-with-patient-grouping-subtitle',
    defaultMessage:
      'To view patients, invite a new one and assign yourself as a provider, or go to Organize patients and assign patients to yourself.',
  },
  noPatientsAvailableForAdminWithoutPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-admin-without-patient-grouping-subtitle',
    defaultMessage: 'Invite new patients and they will appear here.',
  },
  noPatientsAvailableForStaffWithPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-staff-with-patient-grouping-subtitle',
    defaultMessage:
      'Ask one of the administrators of this clinic to invite a patient and assign you as a provider, or to assign you as a provider of existing patients.',
  },
  noPatientsAvailableForStaffWithoutPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-staff-without-patient-grouping-subtitle',
    defaultMessage:
      'Ask one of the administrators of this clinic to invite new patients and they will appear here.',
  },
  statusFilterAllOption: {
    id: 'clinic-patients.status-filter.all-option',
    defaultMessage: 'All',
  },
  statusFilterActiveOption: {
    id: 'clinic-patients.status-filter.active-option',
    defaultMessage: 'Active',
  },
  statusFilterInactiveOption: {
    id: 'clinic-patients.status-filter.inactive-option',
    defaultMessage: 'Inactive',
  },
  statusFilterInvitedOption: {
    id: 'clinic-patients.status-filter.invited-option',
    defaultMessage: 'Invited',
  },
  statusFilterIncompleteProfileOption: {
    id: 'clinic-patients.status-filter.incomplete-profile-option',
    defaultMessage: 'Incomplete Profile',
  },
  statusFilterTitle: {
    id: 'clinic-patients.status-filter.title',
    defaultMessage: 'Status',
  },
  treatmentFilterAllOption: {
    id: 'clinic-patients.treatment-filter.all-option',
    defaultMessage: 'All',
  },
  treatmentFilterTitle: {
    id: 'clinic-patients.treatment-filter.title',
    defaultMessage: 'Treatment',
  },
  providerFilterAllOption: {
    id: 'clinic-patients.provider-filter.all-option',
    defaultMessage: 'All',
  },
  providerFilterTitle: {
    id: 'clinic-patients.provider-filter.title',
    defaultMessage: 'Provider',
  },
  filterSidebarTitle: {
    id: 'clinic-patients.filter-sidebar-title',
    defaultMessage: 'Filter',
  },
  filterSidebarApply: {
    id: 'clinic-patients.filter-sidebar-apply',
    defaultMessage: 'Apply',
  },
  viewFilterTitle: {
    id: 'clinic-patients.view-filter.title',
    defaultMessage: 'View',
  },
  viewFilterMyPatientsOption: {
    id: 'clinic-patients.view-filter.my-patients-option',
    defaultMessage: 'My Patients',
  },
  viewFilterAllPatientsOption: {
    id: 'clinic-patients.view-filter.all-patients-option',
    defaultMessage: 'All Patients',
  },
});
