import { ClinicPatient, didInstalledMobileApp } from '@allurion/domain';
import { useIntl } from 'react-intl';

import { resendPatientInvitation } from 'src/api/PatientApi';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import globalMessages from 'src/messages/global.messages';

import messages from './active-patients-table.messages';

import styles from './ClinicPatientsTable.module.scss';

type Props = {
  patient: ClinicPatient;
  setIsResendInvite: (isResendInvite: boolean) => void;
};

export function ResendInviteLink({ patient, setIsResendInvite }: Props) {
  const intl = useIntl();

  const onClick = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const { uid } = patient;
    const name = `${patient?.name} ${patient?.lastname}`;

    try {
      setIsResendInvite(true);
      const res: any = await resendPatientInvitation(uid.toString());

      if (res?.CodeDeliveryDetails?.AttributeName !== 'email') {
        throw Error('Error when resending invitation');
      }
      toastSuccess(intl.formatMessage(messages.patientResendInvitation, { name }));
    } catch (error: any) {
      toastError(intl.formatMessage(globalMessages.genericError));
    }
    setIsResendInvite(false);
  };

  if (didInstalledMobileApp(patient)) {
    return null;
  }

  return (
    <>
      &nbsp;- {intl.formatMessage(messages.invitedType)} (
      <a href="" onClick={onClick} className={styles.resendLink}>
        {`${intl.formatMessage(messages.resend)}`}
      </a>
      )
    </>
  );
}
