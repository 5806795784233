import { ClinicTreatment, filterOfferedTreatments } from '@allurion/domain';
import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { InsightsApi, buildUrl } from 'src/api/Api';

import { useClinicSettings } from './useClinicSettings';

export const CLINIC_TREATMENTS = (clinicId: string) => `/clinics/${clinicId}/treatments`;

export function useClinicTreatements(clinicId?: string, suspense = false) {
  const { settings } = useClinicSettings(clinicId);
  const url = clinicId && buildUrl(CLINIC_TREATMENTS(clinicId));
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ treatmentList: ClinicTreatment[] }>(_url).then(({ treatmentList }) => {
        treatmentList.sort((first, second) =>
          first.treatment_type_id < second.treatment_type_id ? -1 : 1
        );

        return {
          treatmentList,
        };
      }),
    { revalidateOnFocus: false, dedupingInterval: ONE_MINUTE_IN_MS, suspense }
  );

  return {
    treatmentList: data?.treatmentList ?? [],
    offeredTreatments: filterOfferedTreatments(settings, data?.treatmentList ?? []) ?? [],
    isLoading: isValidating,
    error,
  };
}
