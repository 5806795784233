import { Unit } from 'src/domain/User';
import { Logger } from 'src/services/Logger';

export const poundsPerKilogram = 2.2046226218;

export function getUnitFactor(unit: string) {
  switch (unit?.toLowerCase() as Unit) {
    case 'lb':
      return poundsPerKilogram;

    // kg is the default
    default:
      if (unit !== 'kg') {
        Logger.captureException(new Error(`Invalid unit: [${unit}]`));
      }

      return 1;
  }
}

export function fixNumbers(str: string, places: number, multiplier: number) {
  const num = Number(str) * multiplier;
  const fixed = num.toFixed(places);

  const formattedZero = Number(0).toFixed(places);

  // Avoid negative zeros (for example -0.0)
  if (fixed === `-${formattedZero}`) {
    return formattedZero;
  }

  return fixed === 'NaN' ? 'N/A' : fixed;
}
