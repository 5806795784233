/**
 *
 * Scale Data Table
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  weight: {
    id: 'scale-data-table.weight',
    defaultMessage: 'Weight',
  },
  bmi: {
    id: 'scale-data-table.bmi',
    defaultMessage: 'BMI',
  },
  bodyFatMassPerc: {
    id: 'scale-data-table.bodyFat',
    defaultMessage: 'Body fat %',
  },
  bodyFatMass: {
    id: 'scale-data-table.body-fat-mass',
    defaultMessage: 'Body fat mass',
  },
  leanBodyMassPerc: {
    id: 'scale-data-table.lean-body-mass-percentage',
    defaultMessage: 'Lean body mass %',
  },
  leanBodyMass: {
    id: 'scale-data-table.lean-body-mass',
    defaultMessage: 'Lean body mass',
  },
  bmr: {
    id: 'scale-data-table.bmr',
    defaultMessage: 'BMR',
  },
  actionsColumn: {
    id: 'scale-data-table.actions',
    defaultMessage: 'Delete',
  },
  weightSourceTypeColumn: {
    id: 'scale-data-table.weight-source-type',
    defaultMessage: 'Source',
  },
  clinicWeightSourceType: {
    id: 'scale-data-table.clinic-weight-source-type',
    defaultMessage: 'Clinic Entry',
  },
  patientWeightSourceType: {
    id: 'scale-data-table.patient-weight-source-type',
    defaultMessage: 'Patient Entry',
  },
  scaleWeightSourceType: {
    id: 'scale-data-table.scale-weight-source-type',
    defaultMessage: 'Scale Entry',
  },
  apple_health_kitWeightSourceType: {
    id: 'scale-data-table.apple-health-kit-weight-source-type',
    defaultMessage: 'Apple HealthKit',
  },
  google_health_connectWeightSourceType: {
    id: 'scale-data-table.google-health-connect-weight-source-type',
    defaultMessage: 'Google Health Connect',
  },
  fitbitWeightSourceType: {
    id: 'scale-data-table.fitbit-weight-source-type',
    defaultMessage: 'Fitbit',
  },
});
