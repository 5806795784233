import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionErrorIndicator } from 'src/components/ui/AllurionErrorIndicator';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { getDeviceSelectionOptions } from 'src/twilio/twilio-utils';

import messages from './video-call.messages';

import styles from './PatientVideoCall.module.scss';

type Props = {
  onStartCall: React.MouseEventHandler<HTMLButtonElement>;
  patientId: string;
};

export function VideoCallLobby({ onStartCall, patientId }: Props) {
  const intl = useIntl();
  const { provider } = useCurrentProvider();
  const { trackVideoStart } = useTrackEvent();
  const { patient } = usePatientProfile(patientId);

  const [deviceError, setDeviceError] = useState<boolean>(false);
  const patientName = `${patient?.name} ${patient?.lastname}`;
  const label = intl.formatMessage(messages.instructions, { name: patientName });

  const errorLabel = intl.formatMessage({
    id: 'video-call.not-allowed-error',
    defaultMessage:
      'Video call could not be started. Please make sure to allow permissions, and no other applications are using the video camera or microphone.',
  });

  const handleStarCallClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    try {
      await getDeviceSelectionOptions();
      trackVideoStart({ patientId, providerId: provider?.ProviderID });
      handleStartCall(event);
    } catch (error: any) {
      handleStartCall(error);
    }
  };

  const handleStartCall = (e: any) => {
    const mediaDevicesErrors = [
      'NotAllowedError',
      'NotFoundError',
      'NotReadableError',
      'OverconstrainedError',
    ];

    if (mediaDevicesErrors.includes(e?.name)) {
      setDeviceError(true);

      return;
    }
    onStartCall(e);
  };

  return (
    <div className={styles.videoCall__lobby}>
      <p>{label}</p>
      <TrackedButton
        onClick={handleStarCallClick}
        label="Start video"
        trackLabel="start-video-call"
      />
      {deviceError && (
        <div>
          <AllurionErrorIndicator label={errorLabel} />{' '}
        </div>
      )}
    </div>
  );
}
