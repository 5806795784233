import { ClinicFeatures } from '@allurion/domain';
import { FIFTHTEEN_SECONDS_IN_MS, FIVE_MINUTES_IN_MS } from '@allurion/utils';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { InsightsApi, buildUrl } from 'src/api/Api';
import { MessageNotification } from 'src/domain/Provider';

import { useCurrentProvider } from './useCurrentProvider';
import { useCurrentUser } from './useCurrentUser';
import { useUserClinicsSettings } from './useUserClinicsSettings';

type APIResponse = {
  notifications: MessageNotification[];
  next_page: boolean;
};

const MESSAGE_NOTIFICATION_PATH = `/auth/message-notifications`;
const buildMessageNotificationPath = (username: string) =>
  `${MESSAGE_NOTIFICATION_PATH}?username=${username}`;

export const refreshMessageNotifications = () =>
  mutate(
    (key) =>
      Array.isArray(key)
        ? typeof key[0] === 'string' && key[0].startsWith(MESSAGE_NOTIFICATION_PATH)
        : false,
    undefined,
    {
      revalidate: true,
    }
  );

export function useHasMessageNotifications() {
  const { provider } = useCurrentProvider();
  const [isPhasedOut, setIsPhasedOut] = useState(false);

  const { data, isValidating, mutate } = useSWR(
    isPhasedOut &&
      provider.CognitoUserName && [MESSAGE_NOTIFICATION_PATH, provider.CognitoUserName],
    ([, username]: [string, string]) =>
      InsightsApi.get<APIResponse>(buildMessageNotificationPath(username))
        .then(({ notifications }) => notifications)
        .catch(() => []),
    {
      revalidateOnFocus: true,
      refreshInterval: FIVE_MINUTES_IN_MS,
    }
  );

  useEffect(() => {
    if (isPhasedOut) return;

    const id = setTimeout(() => {
      setIsPhasedOut(true);
    }, FIFTHTEEN_SECONDS_IN_MS);

    return () => clearTimeout(id);
  });

  return {
    data: data && data.length > 0,
    isLoading: isValidating,
    reload: mutate,
  };
}

export function useMessageNotifications() {
  const { user } = useCurrentUser();
  const { hasAccessToFeature } = useUserClinicsSettings();
  const [isUpdating, setIsUpdating] = useState(false);
  const pageSize = 5;

  const shouldFetchNotifications = user.sessionToken && hasAccessToFeature(ClinicFeatures.Messages);

  const { data, error, isValidating, mutate, setSize } = useSWRInfinite(
    getKey,
    (_url) => InsightsApi.get<APIResponse>(_url),
    { revalidateOnFocus: true }
  );

  const removeNotification = async (id: string) => {
    setIsUpdating(true);
    await InsightsApi.delete(`/auth/message-notifications/${id}`);
    await mutate();
    setIsUpdating(false);
    refreshMessageNotifications();
  };

  const clearAll = async () => {
    setIsUpdating(true);
    await InsightsApi.delete(`/auth/message-notifications`);
    await mutate();
    setIsUpdating(false);

    refreshMessageNotifications();
  };

  const notifications = data?.flatMap((page) => page.notifications ?? []) ?? [];

  return {
    isLoading: isValidating || isUpdating,
    error,
    notifications,
    hasNextPage: data?.[data.length - 1]?.next_page ?? false,
    loadMore() {
      setSize((size) => size + 1);
    },
    removeNotification,
    clearAll,
  };

  function getKey(page: number, previousPageData: APIResponse) {
    if (previousPageData && !previousPageData.next_page) {
      return null; // reached the end
    }

    return (
      shouldFetchNotifications &&
      buildUrl('/auth/message-notifications', {
        page: page + 1,
        pageSize,
      })
    );
  }
}
