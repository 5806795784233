import { isPatientGroupingEnabled } from '@allurion/domain';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import messages from './clinic-patients-messages';

import styles from './ClinicPatientsFilter.module.scss';

export type PatientStatusFilter = 'all' | 'active' | 'inactive' | 'invited' | 'incomplete';
export type PatientViewFilter = 'my' | 'all';

export type PatientFilter = {
  status: PatientStatusFilter;
  treatment: string;
  view: PatientViewFilter;
  provider: string;
};
type PatientFilterKey = keyof PatientFilter;

type Props = {
  onClose: () => void;
  onFilterChange: (filter: PatientFilter) => void;
  activeFilter: PatientFilter;
};

export function ClinicPatientsFilter({ onClose, onFilterChange, activeFilter }: Props) {
  const intl = useIntl();
  const [filter, setFilter] = useState<PatientFilter>(activeFilter);
  const { clinicId } = useParams();
  const { isAdmin } = useCurrentProvider();
  const { treatmentList } = useClinicTreatements(clinicId);
  const { getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { providers } = useClinicProviders(clinicId, {
    suspense: false,
  });

  const showProviderFilter = isAdmin && isPatientGroupingEnabled(settings) && filter.view === 'all';
  const showViewFilter = isAdmin && isPatientGroupingEnabled(settings);
  const statusFilterOptions: { value: PatientStatusFilter; label: string }[] = [
    {
      value: 'all',
      label: intl.formatMessage(messages.statusFilterAllOption),
    },
    {
      value: 'active',
      label: intl.formatMessage(messages.statusFilterActiveOption),
    },
    {
      value: 'inactive',
      label: intl.formatMessage(messages.statusFilterInactiveOption),
    },
    {
      value: 'invited',
      label: intl.formatMessage(messages.statusFilterInvitedOption),
    },
    {
      value: 'incomplete',
      label: intl.formatMessage(messages.statusFilterIncompleteProfileOption),
    },
  ];

  const treatmentOptions = [
    {
      label: intl.formatMessage(messages.treatmentFilterAllOption),
      value: '',
    },
    ...treatmentList.map((treatment) => ({
      label: getTreatmentLabel(treatment.custom_treatment_id),
      value: treatment.custom_treatment_id.toString(),
    })),
  ];

  const providersOptions = [
    {
      label: intl.formatMessage(messages.providerFilterAllOption),
      value: '',
    },
    ...providers.map((provider) => ({
      label: `${provider.FirstName} ${provider.LastName}`,
      value: provider.ProviderID,
    })),
  ];

  return (
    <div className={styles.container}>
      <TrackedPageHeader
        title={intl.formatMessage(messages.filterSidebarTitle)}
        navButtonVariant="close"
        onNavButtonClick={onClose}
        onNavButtonClickTrackLabel="cancel-patient-note-form"
        button={{
          label: intl.formatMessage(messages.filterSidebarApply),
          onClick: () => {
            onFilterChange(filter);
            onClose();
          },
          trackLabel: 'apply-patient-filter',
          size: 'sm',
        }}
      />

      <div className={styles.filters}>
        {showViewFilter && (
          <Filter
            title={intl.formatMessage(messages.viewFilterTitle)}
            options={[
              {
                label: intl.formatMessage(messages.viewFilterMyPatientsOption),
                value: 'my',
              },
              {
                label: intl.formatMessage(messages.viewFilterAllPatientsOption),
                value: 'all',
              },
            ]}
            isActive={(option) => option === filter.view}
            onClick={(option) => updateFilter('view', option)}
          />
        )}
        <Filter
          title={intl.formatMessage(messages.statusFilterTitle)}
          options={statusFilterOptions}
          isActive={(option) => option === filter.status}
          onClick={(option) => updateFilter('status', option)}
        />
        <Filter
          title={intl.formatMessage(messages.treatmentFilterTitle)}
          options={treatmentOptions}
          isActive={(option) => option === filter.treatment}
          onClick={(option) => updateFilter('treatment', option)}
        />
        {showProviderFilter && (
          <Filter
            title={intl.formatMessage(messages.providerFilterTitle)}
            options={providersOptions}
            isActive={(option) => option === filter.provider}
            onClick={(option) => updateFilter('provider', option)}
          />
        )}
      </div>
    </div>
  );

  function updateFilter<T extends PatientFilterKey>(filterName: T, option: PatientFilter[T]): void {
    setFilter((prev) => {
      const next = {
        ...prev,
        [filterName]: option,
      };

      //if view is set to 'my' then provider should be empty
      if (filterName === 'view' && option === 'my') {
        next.provider = '';
      }

      return next;
    });
  }
}

type FilterProps<T extends string> = {
  title: string;
  options: {
    label: string;
    value: T;
  }[];
  isActive: (option: T) => boolean;
  onClick: (option: T) => void;
};

function Filter<T extends string>({ title, options, isActive, onClick }: FilterProps<T>) {
  return (
    <div className={styles.filter}>
      <div className={styles.filterTitle}>{title}</div>

      <ul className={styles.filterOptions}>
        {options.map((option) => (
          <li
            className={isActive(option.value) ? styles.active : ''}
            key={option.value}
            onClick={(e) => handleClick(e, option.value)}
            style={{ cursor: 'pointer' }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );

  function handleClick(e: React.MouseEvent, value: T) {
    e.preventDefault();
    e.stopPropagation();

    onClick(value);
  }
}
