import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientProfile } from 'src/domain/patient/Patients';

export const PATIENT = (patientId: string) => `/patients/${patientId}`;

export function usePatientProfile(patientId?: string) {
  const url = patientId && buildUrl(PATIENT(patientId));
  const { data, error, isValidating, mutate } = useSWR(
    url,
    (_url) => InsightsApi.get<PatientProfile>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
      dedupingInterval: ONE_MINUTE_IN_MS,
    }
  );

  return {
    isLoading: isValidating,
    patient: data,
    reload: mutate,
    error,
  };
}

export function fetchPatientData(patientId: string) {
  return InsightsApi.get<PatientProfile>(buildUrl(PATIENT(patientId)));
}

export function deletePatient(patientId: string) {
  return InsightsApi.delete(buildUrl(PATIENT(patientId)));
}

export function makePatientInactive(patientId: string) {
  return InsightsApi.put(buildUrl(`/patients/${patientId}/inactivate`));
}

export function makePatientActive(patientId: string) {
  return InsightsApi.put(buildUrl(`/patients/${patientId}/activate`));
}
