import { isDefined } from '@allurion/utils';

import { breakpoints } from 'src/styles/theme/breakpoints';

import { useThrottledWindowSize } from './useWindowSize';

export function useDevice() {
  const [width] = useThrottledWindowSize();

  const isTablet = isDefined(width) && width < breakpoints.tablet;
  const isMobile = isDefined(width) && width < breakpoints.mobile;
  const isDesktop = isDefined(width) && width < breakpoints.desktop;

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}
