import { ReactNode } from 'react';

import styles from './Tabs.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { classNames } from '../../utils';

export type TabProps = {
  /** How the button style should be? */
  variant?: 'primary' | 'primaryDark' | 'content' | 'contentDark';
  /** The content direction */
  size?: 'sm' | 'lg';
  /** Tab id */
  id: string;
  /** Button contents */
  label?: string;
  /** Left icon component */
  icon?: React.ReactNode | null;
  /**Tooltip options */
  tooltip?: {
    /** Tooltip text */
    text: string;
    /** Tooltip position */
    position?: 'top' | 'bottom' | 'left' | 'right';
  };
  /** Is the tab active? */
  isTabActive?: boolean;
  /** Callback function when the tab is clicked */
  onTabClick?: () => void;
  /** Link href */
  link?: string;
  /** Hide tab */
  hide?: boolean;
  /** Tab template */
  tabTemplate?: (tab: TabProps, className: (isActive: boolean) => string[]) => JSX.Element;
};

export type TabsProps = {
  /** How the button style should be? */
  variant?: 'primary' | 'content' | 'primaryDark' | 'contentDark';
  /** The content direction */
  direction?: 'row' | 'column';
  /** How large should the button be? */
  size?: 'sm' | 'lg';
  /** Tabs contents */
  tabs: TabProps[];
  /** Tabs Alignment*/
  align?: 'left' | 'fill' | 'justify';
  /** Selected tab id */
  selectedTabId?: string;
  /** Callback function when the tab is clicked to update selecTabId state*/
  onTabChange?: (selectedTabId: TabProps) => void;
  /** Tab template */
  tabTemplate?: (tab: TabProps, className: (isActive: boolean) => string[]) => JSX.Element;
};

function Tab({
  variant = 'primary',
  size = 'sm',
  label,
  icon,
  link,
  id,
  hide,
  isTabActive = false,
  tooltip,
  onTabClick,
  tabTemplate,
  ...props
}: TabProps) {
  const tabColor = variant.charAt(0).toUpperCase() + variant.slice(1);
  const tabSize = size.charAt(0).toUpperCase() + size.slice(1);

  const TooltipWrapper = ({ condition, children }: { condition: boolean; children: ReactNode }) => {
    return condition && tooltip?.text ? (
      <Tooltip text={tooltip?.text} placement={tooltip?.position} enableArrow>
        {children}
      </Tooltip>
    ) : (
      children
    );
  };

  const buildClassNames = (isActive: boolean): string[] => [
    styles['tab']!,
    styles[`tab${tabSize}`]!,
    styles[`tab${tabColor}`]!,
    styles[`tab${tabColor}${isActive ? '__active' : ''}`]!,
  ];

  return (
    !hide && (
      <TooltipWrapper condition={!label && Boolean(tooltip)}>
        {tabTemplate ? (
          tabTemplate({ label, icon, link, id }, buildClassNames)
        ) : (
          <div
            className={classNames(...buildClassNames(isTabActive))}
            onClick={onTabClick}
            {...props}
          >
            {icon}
            {label && <span>{label}</span>}
          </div>
        )}
      </TooltipWrapper>
    )
  );
}

export function Tabs({
  variant = 'primary',
  direction = 'row',
  size = 'sm',
  tabs,
  align = 'left',
  onTabChange,
  selectedTabId,
  tabTemplate,
  ...props
}: TabsProps) {
  return (
    <div
      className={classNames(styles['tabs'], styles[`tabs__${direction}`], styles[`tabs__${align}`])}
      {...props}
      data-sentry-unmask
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          variant={variant}
          size={size}
          label={tab.label}
          icon={tab.icon}
          link={tab.link}
          hide={tab.hide}
          id={tab.id}
          isTabActive={tab.isTabActive || tab.id === selectedTabId}
          tooltip={tab.tooltip}
          onTabClick={() => onTabChange && onTabChange(tab)}
          tabTemplate={tabTemplate}
          data-cy={tab.id}
        />
      ))}
    </div>
  );
}
