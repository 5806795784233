/**
 *
 * Scale Data Menu
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'scale-data-menu.date',
    defaultMessage: 'Date',
  },
  weight: {
    id: 'scale-data-menu.weight',
    defaultMessage: 'Weight',
  },
  bmi: {
    id: 'scale-data-menu.bmi',
    defaultMessage: 'BMI',
  },
  bodyFat: {
    id: 'scale-data-menu.bodyFat',
    defaultMessage: 'Body fat %',
  },
  bodyFatMass: {
    id: 'scale-data-menu.body-fat-mass',
    defaultMessage: 'Body fat mass',
  },
  leanBodyMassPerc: {
    id: 'scale-data-menu.lean-body-mass-percentage',
    defaultMessage: 'Lean body mass %',
  },
  leanBodyMass: {
    id: 'scale-data-menu.lean-body-mass',
    defaultMessage: 'Lean body mass',
  },
  bmr: {
    id: 'scale-data-menu.bmr',
    defaultMessage: 'BMR',
  },
});
