import {
  canBeUsedByClinic,
  isAllurionBallonTreatment,
  isCompatibleWithTreatmentSubcategories,
  isLibraryPlusContentEnabled,
} from '@allurion/domain';
import { FormGroup, InputSelector } from '@allurion/ui';
import { isDefined } from '@allurion/utils';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useArticleCollections } from 'src/hooks/useArticleCollections';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useClinicSettings } from 'src/hooks/useClinicSettings';

type Props = {
  clinicId: string;
  selectedTreatmentId: string | undefined;
  error: string | undefined;
  onUpdate: (value: string[]) => void;
  name: string;
};

export const SelectPatientSubscriptions = forwardRef<HTMLInputElement, Props>(
  function SelectPatientSubscriptions(
    { clinicId, selectedTreatmentId, error, onUpdate, ...registerProps }: Props,
    ref
  ) {
    const intl = useIntl();
    const { settings } = useClinicSettings(clinicId);
    const { offeredTreatments } = useClinicTreatements(clinicId);
    const { articleCollections } = useArticleCollections();
    const previousSelectedTreatmentId = usePrevious(selectedTreatmentId);

    const selectedTreatment = isDefined(selectedTreatmentId)
      ? offeredTreatments.find(({ treatmentId }) => treatmentId === selectedTreatmentId)
      : undefined;

    const treatmentSubcategoryName = selectedTreatment?.treatmentSubcategoryName;
    const collectionOptions = useMemo(
      () =>
        articleCollections
          .filter(
            (collection) =>
              isCompatibleWithTreatmentSubcategories(
                collection,
                treatmentSubcategoryName ? [treatmentSubcategoryName] : []
              ) &&
              canBeUsedByClinic(collection, clinicId) &&
              collection.allowSubscription
          )
          .map(({ title, id }) => ({ label: title, value: id })),
      [articleCollections, clinicId, treatmentSubcategoryName]
    );

    const isAllurionTreatmentSelected =
      isDefined(selectedTreatment) &&
      isAllurionBallonTreatment(selectedTreatment.treatmentCategoryId);

    const shouldRenderSubscriptions =
      isDefined(selectedTreatmentId) &&
      (isLibraryPlusContentEnabled(settings) || isAllurionTreatmentSelected) &&
      collectionOptions.length > 0;

    useEffect(() => {
      if (selectedTreatmentId === previousSelectedTreatmentId) {
        return;
      }

      // assign an empty array if there is no treatment selected
      const collectionsValue = selectedTreatmentId
        ? collectionOptions.map((option) => option.value)
        : [];

      onUpdate(collectionsValue);
    }, [selectedTreatmentId, previousSelectedTreatmentId, onUpdate, collectionOptions]);

    if (!shouldRenderSubscriptions) {
      return null;
    }

    return (
      <FormGroup
        label={intl.formatMessage({
          id: 'patient-profile.library-content.manage-subscription',
          defaultMessage: 'Manage Content Subscription',
        })}
        error={error}
      >
        {collectionOptions.map((option) => (
          <InputSelector
            key={option.value}
            type="checkbox"
            ref={ref}
            {...registerProps}
            label={option.label}
            value={option.value}
          />
        ))}
      </FormGroup>
    );
  }
);

function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
