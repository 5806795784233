import { ReactNode } from 'react';
import styles from './Table.module.scss';
import { ArrowUpIcon } from '../../icons/ArrowUpIcon';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { classNames } from '../../utils';

type TableColumn<T> = {
  /** column title */
  title: string | JSX.Element;
  /** field name */
  field: keyof T;
  /** will hide the entire column */
  hide?: boolean;
  /** will enable/disable sorting the column */
  sortable?: boolean;
  /** will define the column alignment */
  align?: 'left' | 'center' | 'right';
  /** will format the column value */
  formatter?: (value: T[keyof T], row: T) => ReactNode;
};

type Props<T> = {
  /** table column metadata */
  columns: TableColumn<T>[];
  /** dataset for rendering rows */
  data: T[];
  /** fieldname to be used in react key prop */
  keyField?: keyof T;
  /** sorting fieldname and direction ([+ | -]fieldName) */
  sortBy?: string;
  /** callback that is called when the sorted column change */
  onSortChange?: (sortBy: string) => void;
  /** borderStyle is a function that can change the border style based on data */
  borderStyle?: (row: T) => string | undefined;
};

export function Table<T extends Record<string, unknown>>({
  columns,
  data = [],
  keyField,
  sortBy,
  onSortChange,
  borderStyle,
}: Props<T>) {
  const visibleColumns = columns.filter((column) => !column.hide);
  const sortDirection = sortBy?.[0] === '+' ? 1 : -1;
  const sortField = sortBy?.slice(1);

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            {visibleColumns.map(({ title, field, sortable = true, align = 'left' }) => (
              <th
                key={String(field)}
                onClick={handleHeaderClick(String(field), sortable)}
                className={classNames(
                  sortable && styles.sortableColumnHeader,
                  sortField === String(field) && styles.sortedColumn
                )}
              >
                <div className={classNames(styles[`align-${align}`])}>
                  {title}
                  {sortable && (
                    <SortIcon direction={sortDirection} isVisible={sortField === String(field)} />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={(keyField && row[keyField]?.toString()) || index}
              className={borderStyle?.(row)}
            >
              {visibleColumns.map(({ field, align, formatter }) => (
                <td
                  key={String(field)}
                  className={classNames(
                    styles[`align-${align}`],
                    sortField === String(field) && styles.sortedColumn
                  )}
                >
                  {(formatter ? formatter(row[field], row) : (row[field] as ReactNode)) ?? '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  function handleHeaderClick(fieldName: string, isEnabled: boolean) {
    if (!isEnabled) return undefined;

    return () => {
      if (sortBy === `+${fieldName}`) {
        onSortChange?.(`-${fieldName}`);
      } else {
        onSortChange?.(`+${fieldName}`);
      }
    };
  }
}

function SortIcon({ isVisible, direction }: { isVisible: boolean; direction: number }) {
  return isVisible ? direction > 0 ? <ArrowDownIcon /> : <ArrowUpIcon /> : null;
}
