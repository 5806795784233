import { buildUrl, InsightsApi } from './Api';

export const createProviderUser = (data: {
  email: string;
  lang: string;
  firstname: string;
  lastname: string;
  role: string;
  clinics: string[];
}) => {
  const query = {
    clinicID: data.clinics.join(','),
  };
  const body = {
    ...data,
    email: data.email.toLowerCase(),
  };

  return InsightsApi.post<any>(buildUrl('/providers/', query), body);
};

export function createProviderPassword(
  username: string,
  password: string,
  confirmationCode: string
) {
  return InsightsApi.post<any>(
    buildUrl('/confirm-signup'),
    {
      username,
      password,
      confirmationCode,
    },
    {
      isPublic: true,
    }
  );
}

export function updateUserRole(providerID: string | number, roleID: string, clinicID: string) {
  const query = { clinicID };

  return InsightsApi.put<any>(buildUrl(`/providers/${providerID}/clinics`, query), {
    RoleID: roleID,
  });
}

export function updateProfile({
  firstName,
  lastName,
  unitsPreference,
  notificationsEnabled,
  notificationsFrequency,
}: {
  firstName: string;
  lastName: string;
  unitsPreference: string;
  notificationsEnabled: boolean;
  notificationsFrequency?: string;
}) {
  const parameters = {
    FirstName: firstName,
    LastName: lastName,
    UnitsPreference: unitsPreference,
    NotificationsEnabled: notificationsEnabled,
    NotificationsFrequency: notificationsFrequency ?? null,
  };

  return InsightsApi.patch<any>(buildUrl(`/auth/provider`), parameters);
}

export function updateProviderProfile(
  providerID: string | number,
  firstname: string,
  lastname: string,
  unitsPreference: string
) {
  return InsightsApi.patch<any>(buildUrl(`/providers/${providerID}`), {
    FirstName: firstname,
    LastName: lastname,
    UnitsPreference: unitsPreference,
  });
}

export function acceptPrivacyPolicy() {
  return InsightsApi.patch<any>(buildUrl('/auth/provider'), {
    AcceptPolicy: true,
  });
}

export function disableUser(providerId: string | number) {
  return InsightsApi.delete<any>(buildUrl(`/providers/${providerId}`));
}
