export type Clinic = {
  ID: string;
  post_title: string;
  address: {
    address: string;
    state: string;
    city: string;
    country: string;
  };
};

export type Role = {
  RoleID: number;
  Name: string;
  Description: string;
};

export type NoteTopic = {
  TopicID: number;
  TopicName: string;
  TopicDescription: string;
};

export type Treatment = {
  TreatmentID: string;
  Treatment: string;
};

export type ClinicMetadata = {
  roles: Role[];
  noteTopics: NoteTopic[];
  treatments: Treatment[];
};

type AggregateDataValue = number | null;

export type ClinicStats = {
  hospitalid: number;
  calc_date?: string;
  total_patients: number;
  active_patients: number;
  avg_4mo_tbwl: number;
  daily_weigh_rate_clinic: number;
  weekly_weigh_rate_clinic: number;
  monthly_weigh_rate_clinic: number;
  median_tbwl_mo1?: AggregateDataValue;
  median_tbwl_mo10?: AggregateDataValue;
  median_tbwl_mo11?: AggregateDataValue;
  median_tbwl_mo12?: AggregateDataValue;
  median_tbwl_mo2?: AggregateDataValue;
  median_tbwl_mo3?: AggregateDataValue;
  median_tbwl_mo4?: AggregateDataValue;
  median_tbwl_mo5?: AggregateDataValue;
  median_tbwl_mo6?: AggregateDataValue;
  median_tbwl_mo7?: AggregateDataValue;
  median_tbwl_mo8?: AggregateDataValue;
  median_tbwl_mo9?: AggregateDataValue;
  fifth_perc_tbwl_mo1?: AggregateDataValue;
  fifth_perc_tbwl_mo10?: AggregateDataValue;
  fifth_perc_tbwl_mo11?: AggregateDataValue;
  fifth_perc_tbwl_mo12?: AggregateDataValue;
  fifth_perc_tbwl_mo2?: AggregateDataValue;
  fifth_perc_tbwl_mo3?: AggregateDataValue;
  fifth_perc_tbwl_mo4?: AggregateDataValue;
  fifth_perc_tbwl_mo5?: AggregateDataValue;
  fifth_perc_tbwl_mo6?: AggregateDataValue;
  fifth_perc_tbwl_mo7?: AggregateDataValue;
  fifth_perc_tbwl_mo8?: AggregateDataValue;
  fifth_perc_tbwl_mo9?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo1?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo10?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo11?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo12?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo2?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo3?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo4?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo5?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo6?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo7?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo8?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo9?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo1?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo10?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo11?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo12?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo2?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo3?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo4?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo5?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo6?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo7?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo8?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo9?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo1?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo10?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo11?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo12?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo2?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo3?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo4?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo5?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo6?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo7?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo8?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo9?: AggregateDataValue;
};

export type ClinicSettings = {
  ClinicID: string;
  features?: string[];
  weightUnit?: string;
  treatmentsOffered?: number[];
};

export type ParsedClinicSettings = Omit<ClinicSettings, 'treatmentsOffered'> & {
  treatmentsOffered?: string[];
};

export type ParsedClinicData = {
  id: string;
  value: string;
  label: string;
  clinicName: string;
  city: string;
  country: string;
};

export function parseClinicsData(data?: Clinic[]): ParsedClinicData[] {
  return (data ?? []).map((d) => ({
    id: d.ID,
    value: d.ID,
    label: d.post_title,
    clinicName: d.post_title,
    city: d.address.city,
    country: d.address.country,
  }));
}
