import { isDefined, isString } from '@allurion/utils';

import { getUserSessionToken } from 'src/helpers/userSession';
import { Logger } from 'src/services/Logger';
import env from 'src/utils/env';

export const { BASE_API_NEW, BASE_EXPORT_API, BASE_CONTENT_API } = env;

class Api {
  baseUrl: string;

  throwOnStatusCode = false;

  defaultHeaders: any;

  constructor(baseUrl: string, defaultHeaders: any = {}) {
    this.baseUrl = baseUrl;
    this.defaultHeaders = defaultHeaders;
  }

  async get<R>(url: string, opts: any = {}): Promise<R> {
    return this.httpRequest(url, 'GET', opts);
  }

  async post<R>(url: string, body: any = {}, opts: any = {}): Promise<R> {
    return this.httpRequestWithBody(url, 'POST', body, opts);
  }

  async patch<R>(url: string, body: any = {}, opts: any = {}): Promise<R> {
    return this.httpRequestWithBody(url, 'PATCH', body, opts);
  }

  async put<R>(url: string, body: any = {}, opts: any = {}): Promise<R> {
    return this.httpRequestWithBody(url, 'PUT', body, opts);
  }

  async delete<R>(url: string, body: any = {}, opts: any = {}): Promise<R> {
    return this.httpRequestWithBody(url, 'DELETE', body, opts);
  }

  async httpRequestWithBody<R>(
    url: string,
    method: string,
    body: any = {},
    opts: any = {}
  ): Promise<R> {
    return this.httpRequest(url, method, {
      ...opts,
      body: JSON.stringify(body),
    });
  }

  async httpRequest(
    url: string,
    method: string,
    { isFile = false, isPublic = false, ...options }: any = {}
  ) {
    const token = await getUserSessionToken();

    if (!token && !isPublic) {
      throw new Error('Unauthorized');
    }

    const withToken = token ? { Authorization: `Bearer ${token}` } : {};

    const requestInit = {
      ...options,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        ...this.defaultHeaders,
        ...options.headers,
        ...withToken,
      },
      method,
    };

    return fetch(`${this.baseUrl}${url}`, requestInit).then(async (res) => {
      if (res.status === 401) {
        throw new Error('Unauthorized');
      }

      if (res.status === 403) {
        throw new Error('Forbidden');
      }

      if (isFile && res.status < 400) {
        return res.blob();
      }

      const body = await res.json();

      if (res.status < 400) {
        return body;
      }

      const error = new Error((body as { error: string })?.error ?? 'Internal server error');

      if (res.status >= 500) {
        Logger.captureException(error);
      }

      throw error;
    });
  }
}

export const InsightsApi = new Api(BASE_API_NEW!);
export const InsightsExportApi = new Api(BASE_EXPORT_API!);
// throw an error if statusCode >= 400
InsightsApi.throwOnStatusCode = true;
export const ContentApi = new Api(BASE_CONTENT_API!);

export function buildUrl(
  pathname: string,
  query?: { [attribute: string]: string | number | boolean | undefined }
) {
  let url = pathname;

  const queryStr = Object.entries(query ?? {})
    .filter(([, value]) => isDefined(value) && (!isString(value) || value !== ''))
    .map(([attribute, value]) => `${attribute}=${value}`)
    .join('&');

  if (queryStr) {
    url += `?${queryStr}`;
  }

  return url;
}
