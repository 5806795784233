import { ClinicPatient } from '@allurion/domain';
import { classNames } from '@allurion/ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Loader } from 'src/components/ui/Loader';

import { PatientSuccessPredictorBadge } from './PatientSuccessPredictorBadge';
import { ResendInviteLink } from './ResendInviteLink';
import { usePatientFormatters } from './usePatientFormatters';

import styles from './ClinicPatientsCards.module.scss';

type Props = {
  data: ClinicPatient[];
  isAtRiskEnabled: boolean;
  clinicId: string;
};

export function ClinicPatientsCards({ data, isAtRiskEnabled, clinicId }: Props) {
  const {
    getPatientFullNameAndAge,
    getPatientTreatmentLabel,
    getPatientCurrentWeight,
    getPatientCurrentBmi,
    getPatientWeightLossSincePlacement,
    getPatientTBWL,
    getPatientDaysInTreatment,
  } = usePatientFormatters(clinicId);
  const [isResendInvite, setIsResendInvite] = useState<boolean>(false);

  const getMetrics = (patient: ClinicPatient) => {
    const bmi = getPatientCurrentBmi(patient);
    const tbwl = getPatientTBWL(patient);

    return [
      getPatientCurrentWeight(patient),
      bmi && <>BMI&nbsp;{bmi}</>,
      getPatientWeightLossSincePlacement(patient),
      tbwl && <>{tbwl}&nbsp;TBWL</>,
      getPatientDaysInTreatment(patient),
    ].filter(Boolean);
  };

  return (
    <div className={styles.cards}>
      <Loader isLoading={isResendInvite} />

      {data.map((patient) => (
        <Link
          key={patient.uid}
          className={classNames(
            styles.card,
            patient.latest_treatment_id ? undefined : styles.missingTreatment
          )}
          to={`/clinic/${clinicId}/patient/${patient.uid}`}
        >
          <div className={styles.header}>
            <div>
              <div className={styles.title}>{getPatientFullNameAndAge(patient)}</div>
              <div className={styles.subtitle}>
                {getPatientTreatmentLabel(patient)}
                <ResendInviteLink patient={patient} setIsResendInvite={setIsResendInvite} />
              </div>
            </div>
            {isAtRiskEnabled && (
              <span className={styles.badge}>
                <PatientSuccessPredictorBadge
                  at_risk_display={patient.at_risk_display}
                  at_risk_score={patient.at_risk_score}
                />
              </span>
            )}
          </div>
          <div className={styles.metrics}>
            {getMetrics(patient).map((metric, index) => (
              <span key={index}>{metric}</span>
            ))}
          </div>
        </Link>
      ))}
    </div>
  );
}
