import useSWR from 'swr';

import { fetchProviders } from 'src/api/ProviderApi';

export function useClinicProviders(clinicId?: string, { suspense = true } = {}) {
  const { data, error, isValidating } = useSWR(
    clinicId && ['fetchProviders', clinicId],
    ([, clinicId]: [string, string]) => fetchProviders(clinicId),
    {
      revalidateOnFocus: false,
      suspense,
    }
  );

  return {
    providers: data ?? [],
    error,
    isLoading: isValidating,
  };
}

export function useProviders(clinicIds?: string[]) {
  const clinicQuery = clinicIds?.join(',');

  const { data, error, isValidating } = useSWR(
    ['fetchProviders', clinicQuery],
    ([, clinicQuery]: [string, string]) => fetchProviders(clinicQuery),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return {
    providers: data ?? [],
    error,
    isLoading: isValidating,
  };
}
