export function CheckCircleIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5381_60)">
        <path
          d="M8.00008 1.33317C4.31818 1.33317 1.33341 4.31794 1.33341 7.99984C1.33341 11.6817 4.31818 14.6665 8.00008 14.6665C11.682 14.6665 14.6667 11.6817 14.6667 7.99984C14.6667 4.31794 11.682 1.33317 8.00008 1.33317Z"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6L6.875 10.125L5 8.25"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_5381_60">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
