import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';
import { ClinicMetadata } from 'src/domain/Clinic';

export const METADATA = '/metadata';

export function useMetadata() {
  const { data, error, isValidating } = useSWR('getMetadata', getMetadata, {
    revalidateOnFocus: false,
    suspense: true,
  });

  return {
    metadata: data,
    error,
    isLoading: isValidating,
  };
}

async function getMetadata(): Promise<ClinicMetadata> {
  return InsightsApi.get<ClinicMetadata>(METADATA, { isPublic: true });
}
