import { isError } from '@allurion/utils';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { Provider } from 'src/domain/Provider';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { updatePatientProviders } from 'src/hooks/usePatientProviders';
import { Logger } from 'src/services/Logger';

import { PatientProvidersForm } from './PatientProvidersForm';

type Props = {
  patientId: string;
  clinicId?: string;
  setIsLoading: (v: boolean) => void;
  isOpen?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  providers?: Provider[];
};

export function EditPatientProvidersDialog({
  patientId,
  clinicId,
  setIsLoading = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  providers,
}: Props) {
  const intl = useIntl();
  const { providers: allProviders } = useClinicProviders(clinicId);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const handleOnConfirm = async (selectedProviders?: Provider[]) => {
    if (!selectedProviders) {
      return;
    }
    const providersIds = selectedProviders?.map(({ ProviderID }) => ({ providerID: ProviderID }));

    setIsLoading(true);
    try {
      await updatePatientProviders(providersIds, patientId);
      setIsLoading(false);
      toastSuccess(
        intl.formatMessage({
          id: 'provider-access.providers-updated',
          defaultMessage: 'Providers updated',
        })
      );
      trackFormSuccess('patient-providers');
      onConfirm();
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      toastError(errorMessage);
      trackFormError('patient-providers', errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <PatientProvidersForm
          providers={providers}
          onConfirm={handleOnConfirm}
          onCancel={onCancel}
          allProviders={allProviders}
        />
      </ModalContent>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.White};
  color: ${({ theme }) => theme.colors.Primary};
`;

const ModalContent = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  ${({ theme }) => theme.media.max.desktop`
    padding-right: 12px;
    padding-left: 12px;
  `}
`;
