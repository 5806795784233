import { calculateAge, isNotNull, isNull, isUndefined, toDateStr } from '@allurion/utils';
import { AtRiskDisplay } from './risk';

export type PatientWithStats = {
  active_patient: boolean;
  age: number;
  current_bmi: number;
  current_weight: number;
  days_in_program: number;
  hospitalid: number;
  lastname: string;
  latest_treatment_id: string;
  latest_treatment_category_id?: string;
  latest_treatment_start_date: string;
  name: string;
  perc_weight_loss_since_placement: number;
  starting_bmi: number;
  starting_weight: number;
  uid: number;
  userid: null;
  uuid: string;
  weight_loss_since_placement: number;
  at_risk_score: number;
  at_risk_display: AtRiskDisplay;
  last_weigh_in: string;
};

export function didInstalledMobileApp(patient: { uuid: string | null }) {
  return isNotNull(patient.uuid);
}

export function getPatientAge({ birthday }: { birthday: string | null }) {
  if (isNull(birthday) || isUndefined(birthday)) {
    return null;
  }

  return calculateAge(toDateStr(birthday));
}
