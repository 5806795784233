import { isPatientGroupingEnabled, isValidStartDate } from '@allurion/domain';
import { Card, FormGroup, InputGroup, InputSelector, SelectInput, TextInput } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedButton, TrackedPageHeader } from 'src/analytics/TrackedUI';
import { invitePatient } from 'src/api/PatientApi';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { getUserLanguage } from 'src/helpers/locale';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useClinicPatients } from 'src/hooks/useClinicPatients';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useDevice } from 'src/hooks/useDevice';
import { useLocale } from 'src/hooks/useLocale';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import globalMessages from 'src/messages/global.messages';
import {
  InvitePatientFormData,
  InvitePatientApiPayload,
  ProviderSelectorOption,
} from 'src/pages/InvitePatientPage/InvitePatient.types';
import treatmentListTranslations from 'src/pages/PatientPage/Overview/PatientTreamentsCard/PatientTreamentsCard.translations';
import { Logger } from 'src/services/Logger';

import { usePatientFormatters } from '../ClinicPage/ClinicPatientsPage/usePatientFormatters';
import { Container, InnerContainer } from '../shared-page-elements';

import translations from './InvitePatient.translations';
import { SelectPatientSubscriptions } from './SelectPatientSubscriptions';

import styles from './InvitePatientForm.module.scss';

type Props = {
  data: InvitePatientApiPayload;
  next: (payload: InvitePatientApiPayload) => void;
  clinicId: string;
};

export function InvitePatientForm({ data, clinicId, next }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { trackFormSuccess, trackFormError } = useTrackEvent();
  const { locale } = useLocale();
  const lang = getUserLanguage(locale);
  const intl = useIntl();
  const { toPreviousPage } = useAppNavigate();

  const { isMobile } = useDevice();
  const { offeredTreatments } = useClinicTreatements(clinicId);
  const { getTreatmentCategoryLabel, getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { clinic } = useClinic(clinicId);
  const { reload: reloadClinicPatients } = useClinicPatients(clinicId, {
    suspense: false,
    showAll: false,
  });
  const { getTreatmentStartDateLabel } = usePatientFormatters(clinicId);

  const treatmentsOptions =
    offeredTreatments.map((treatment) => {
      return {
        label: `${getTreatmentCategoryLabel(treatment.treatment_type_id, 'short')}
      /
      ${getTreatmentLabel(treatment.custom_treatment_id, 'short')}`,
        value: treatment.custom_treatment_id,
      };
    }) ?? [];

  const { providers } = useClinicProviders(clinicId);
  const providerOptions: ProviderSelectorOption[] = providers
    .map((provider) => ({
      value: JSON.stringify({ providerID: provider.ProviderID }),
      label: `${provider.FirstName} ${provider.LastName}`,
    }))
    .filter((provider) => provider.label.trim().length > 0)
    .sort((a, b) => a.label.localeCompare(b.label));

  const onSubmit = async (values: InvitePatientFormData) => {
    if (values.startDate && !isValidStartDate(values.startDate)) {
      setError('startDate', {
        type: 'custom',
        message: intl.formatMessage(treatmentListTranslations.startDateErrorMessage),
      });

      return false;
    }

    const selectedProviders = providerOptions.filter((provider) =>
      values.providers?.includes(provider.value)
    );

    setIsLoading(true);

    try {
      const data: InvitePatientApiPayload = { ...values, providers: selectedProviders };
      const { providers, collections, ...patientData } = data;
      const payload = {
        ...patientData,
        lang,
        clinic: clinicId,
        providers: providers?.map((provider) => ({
          providerID: JSON.parse(provider.value).providerID,
        })),
        collections: collections?.map((collection) => ({
          collection_id: collection,
          is_active: true,
        })),
      };

      const { UID } = await invitePatient(payload);

      await reloadClinicPatients();
      // NOTE: Re-use of translation label, email === name
      const email = `${data.firstName} ${data.lastName}`;

      data.patientId = UID;

      toastSuccess(intl.formatMessage(translations.invitation, { email }));
      trackFormSuccess('invite-patient');
      next(data);
      sessionStorage.removeItem('add-patient-form');
    } catch (error: any) {
      const errorMessage = isError(error) ? error.message : error;

      trackFormError('invite-patient', { error: errorMessage });

      if (errorMessage.includes('An account with the given email already exists')) {
        toastError(intl.formatMessage(translations.emailAlreadyInUseMessage));
      } else {
        Logger.captureException(error);
        toastError(errorMessage);
      }
    }
    setIsLoading(false);
  };

  const AddPatientSchema = Yup.object().shape({
    firstName: Yup.string().required(intl.formatMessage(globalMessages.required)),
    lastName: Yup.string().required(intl.formatMessage(globalMessages.required)),
    email: Yup.string().email().required(intl.formatMessage(globalMessages.required)),
    treatmentType: Yup.number()
      .required(intl.formatMessage(globalMessages.required))
      .moreThan(0, intl.formatMessage(globalMessages.required)),
    startDate: Yup.string().required(intl.formatMessage(globalMessages.required)),
    collections: Yup.array(),
    providers: isPatientGroupingEnabled(settings)
      ? Yup.array().min(1, intl.formatMessage(globalMessages.required))
      : Yup.array(),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm<InvitePatientFormData>({
    resolver: yupResolver(AddPatientSchema),
    defaultValues: {
      ...data,
      treatmentType: data.treatmentType === 0 ? undefined : data.treatmentType,
      providers: data.providers?.map(({ value }) => value),
    },
  });

  const selectedTreatmentType = watch('treatmentType');

  const treatmentCategoryId = offeredTreatments.find(
    ({ treatmentCategoryId }) => treatmentCategoryId === selectedTreatmentType?.toString()
  )?.treatmentCategoryId;

  const selectedProviders = watch('providers');
  const providerGridMaxHeight = `${providerOptions.length > 20 ? providerOptions.length * 10 : 200}px`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <TrackedPageHeader
        title={intl.formatMessage(translations.title)}
        onNavButtonClick={() => toPreviousPage()}
        button={{
          label: intl.formatMessage(translations.confirm),
          disabled: !isDirty,
          size: 'sm',
          trackLabel: 'submit-clinic-settings-form',
          type: 'submit',
        }}
      />
      <Loader isLoading={isLoading} />

      <Container>
        <InnerContainer>
          <div className={styles.container}>
            <Card title={intl.formatMessage(translations.personalInformationTitle)}>
              <div className={styles.fullName}>
                <FormGroup
                  label={intl.formatMessage(translations.firstName)}
                  error={errors.firstName?.message}
                >
                  <TextInput {...register('firstName')} />
                </FormGroup>
                <FormGroup
                  label={intl.formatMessage(translations.lastName)}
                  error={errors.lastName?.message}
                >
                  <TextInput {...register('lastName')} />
                </FormGroup>
              </div>
              <FormGroup
                label={intl.formatMessage(translations.email)}
                error={errors.email?.message}
              >
                <TextInput {...register('email')} />
              </FormGroup>
            </Card>
            <Card title={intl.formatMessage(translations.treatmentInformationTitle)}>
              <FormGroup label={intl.formatMessage(translations.clinic)}>
                <div className={styles.clinicName}>{clinic?.post_title}</div>
              </FormGroup>
              <FormGroup
                label={intl.formatMessage(translations.treatment)}
                error={errors.treatmentType?.message}
              >
                <SelectInput
                  {...register('treatmentType', {
                    setValueAs: (value) => (value.length > 0 ? Number(value) : undefined),
                  })}
                  options={treatmentsOptions}
                  placeholder={intl.formatMessage(
                    treatmentListTranslations.treatmentTypePlaceholder
                  )}
                />
              </FormGroup>
              <FormGroup
                label={getTreatmentStartDateLabel(treatmentCategoryId)}
                error={errors.startDate?.message}
              >
                <InputGroup textInput={{ type: 'date', ...register('startDate') }} />
              </FormGroup>

              <SelectPatientSubscriptions
                clinicId={clinicId}
                selectedTreatmentId={selectedTreatmentType?.toString()}
                error={errors.collections?.message}
                onUpdate={(value) => setValue('collections', value)}
                {...register('collections')}
              />
            </Card>

            {isPatientGroupingEnabled(settings) && (
              <Card
                title={intl.formatMessage(translations.providerAccess)}
                className={styles.fullWidthCard}
              >
                <div className={styles.providerHeader}>
                  <p className={styles.providerHelpText}>
                    ({intl.formatMessage(translations.providerAccessDescription)})
                  </p>
                  <div className={styles.providerActions}>
                    <TrackedButton
                      label={intl.formatMessage(globalMessages.selectAll)}
                      size="xs"
                      variant="secondary"
                      trackLabel="invite-patient-select-all-providers"
                      onClick={selectAllProviders}
                    />
                    <TrackedButton
                      label={intl.formatMessage(globalMessages.unselectAll)}
                      size="xs"
                      variant="secondary"
                      trackLabel="invite-patient-unselect-all-providers"
                      onClick={unselectAllProviders}
                    />
                  </div>
                </div>
                <FormGroup
                  label={intl.formatMessage(translations.providerAccessFieldLabel)}
                  error={errors.providers?.message}
                >
                  <div
                    className={styles.providerGrid}
                    style={{ maxHeight: isMobile ? 'auto' : providerGridMaxHeight }}
                  >
                    {providerOptions.map((option) => (
                      <InputSelector
                        key={option.value}
                        type="checkbox"
                        {...register('providers')}
                        label={option.label}
                        value={option.value}
                        className={
                          selectedProviders?.includes(option.value)
                            ? styles.selected
                            : styles.unselected
                        }
                      />
                    ))}
                  </div>
                </FormGroup>
              </Card>
            )}
          </div>
        </InnerContainer>
      </Container>
    </form>
  );

  function selectAllProviders() {
    setValue(
      'providers',
      providerOptions.map((option) => option.value)
    );
  }

  function unselectAllProviders() {
    setValue('providers', []);
  }
}
