import { Badge, CloseIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import messages from './clinic-patients-messages';
import { PatientFilter, PatientStatusFilter, PatientViewFilter } from './ClinicPatientsFilter';

import styles from './ClinicPatientsActiveFilters.module.scss';

type Props = {
  clinicId: string;
  filters: PatientFilter;
  onFilterChange: (filters: PatientFilter) => void;
};

export function ClinicPatientsActiveFilters({ clinicId, filters, onFilterChange }: Props) {
  const intl = useIntl();
  const { getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { providers } = useClinicProviders(clinicId, {
    suspense: false,
  });

  const statusFilter = filters.status;
  const viewFilter = filters.view;
  const treatmentFilter = filters.treatment;
  const providerFilter = filters.provider;
  const providersOptions = providers.map((provider) => ({
    label: `${provider.FirstName} ${provider.LastName}`,
    value: provider.ProviderID,
  }));
  const selectedProvider = providersOptions.find(({ value }) => providerFilter === value)?.label;

  const statusFilterTranslationMap: Record<PatientStatusFilter, string> = {
    'active': intl.formatMessage(messages.statusFilterActiveOption),
    'all': intl.formatMessage(messages.statusFilterAllOption),
    'inactive': intl.formatMessage(messages.statusFilterInactiveOption),
    'incomplete': intl.formatMessage(messages.statusFilterIncompleteProfileOption),
    'invited': intl.formatMessage(messages.statusFilterInvitedOption),
  };

  const viewFilterTranslationMap: Record<PatientViewFilter, string> = {
    'all': intl.formatMessage(messages.viewFilterAllPatientsOption),
    'my': intl.formatMessage(messages.viewFilterMyPatientsOption),
  };

  return (
    <div className={styles.container}>
      <Badge
        label={`${intl.formatMessage(messages.statusFilterTitle)}: ${statusFilterTranslationMap[statusFilter]}`}
        variant="secondary"
        size="sm"
        rightIcon={
          statusFilter !== 'active' ? (
            <a href="" onClick={clearStatusFilter} style={{ display: 'flex' }}>
              <CloseIcon />
            </a>
          ) : (
            ''
          )
        }
      />
      <Badge
        label={`${intl.formatMessage(messages.viewFilterTitle)}: ${viewFilterTranslationMap[viewFilter]}`}
        variant="secondary"
        size="sm"
        rightIcon={
          viewFilter !== 'my' ? (
            <a href="" onClick={clearViewFilter} style={{ display: 'flex' }}>
              <CloseIcon />
            </a>
          ) : (
            ''
          )
        }
      />
      {treatmentFilter && (
        <Badge
          label={`${intl.formatMessage(messages.treatmentFilterTitle)}: ${getTreatmentLabel(treatmentFilter)}`}
          variant="secondary"
          size="sm"
          rightIcon={
            <a href="" onClick={clearTreatmentFilter} style={{ display: 'flex' }}>
              <CloseIcon />
            </a>
          }
        />
      )}
      {providerFilter && (
        <Badge
          label={`${intl.formatMessage(messages.providerFilterTitle)}: ${selectedProvider}`}
          variant="secondary"
          size="sm"
          rightIcon={
            <a href="" onClick={clearProviderFilter} style={{ display: 'flex' }}>
              <CloseIcon />
            </a>
          }
        />
      )}
    </div>
  );

  function clearStatusFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    e.preventDefault();
    onFilterChange({
      ...filters,
      status: 'active',
    });
  }

  function clearViewFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    e.preventDefault();
    onFilterChange({
      ...filters,
      view: 'my',
    });
  }

  function clearTreatmentFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    e.preventDefault();
    onFilterChange({
      ...filters,
      treatment: '',
    });
  }

  function clearProviderFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    e.preventDefault();
    onFilterChange({
      ...filters,
      provider: '',
    });
  }
}
