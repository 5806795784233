import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Footer } from 'src/components/Footer/Footer';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ResetPasswordForm } from 'src/forms/ResetPassword/ResetPasswordForm';
import emailMessages from 'src/forms/ValidateEmailCode/validate-email-code-messages';
import { ValidateEmailCodeForm } from 'src/forms/ValidateEmailCode/ValidateEmailCode';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import LogoImgSrc from 'src/images/wordmark-white.svg';
import { Logger } from 'src/services/Logger';

import messages from '../messages/auth.messages';

import { FullscreenContainer } from './shared-page-elements';

export default function ResetPasswordPage() {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { toLoginPage } = useAppNavigate();
  const [step, setStep] = useState<'request' | 'validate'>('request');

  return (
    <FullscreenContainer>
      <Seo title="Reset Password" />
      <Content>
        <LogoTitle>
          <Logo src={LogoImgSrc} alt="Allurion" />
        </LogoTitle>

        {step === 'request' ? (
          <ResetPasswordForm onSubmit={onEmailSubmit} />
        ) : step === 'validate' ? (
          <ValidateEmailCodeForm
            onSubmit={updatePassword}
            resendEmail={() => requestVerificationCode(email)}
          />
        ) : null}

        <Footer />
      </Content>
      <Loader isLoading={isLoading} />
    </FullscreenContainer>
  );

  function requestVerificationCode(email: string) {
    setIsLoading(true);

    return Auth.forgotPassword(email)
      .then(() => {
        setIsLoading(false);
        toastSuccess(intl.formatMessage(messages.passwordResetRequestSuccess));
      })
      .catch((err) => {
        setIsLoading(false);
        toastError(intl.formatMessage(messages.passwordResetRequestFailure));
        Logger.captureException(err);
        throw err;
      });
  }

  async function onEmailSubmit({ email: userEmail }: { email: string }) {
    setEmail(userEmail);
    requestVerificationCode(userEmail).then(() => setStep('validate'));
  }

  async function updatePassword({ code, password }: { code: string; password: string }) {
    setIsLoading(true);

    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        setIsLoading(false);
        toastSuccess(intl.formatMessage(messages.passwordResetSuccess));
        toLoginPage(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
          toastError(intl.formatMessage(emailMessages.incorrectCode));
        } else {
          toastError(intl.formatMessage(messages.passwordResetFailure));
        }

        Logger.captureException(err);
      });
  }
}

const LogoTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
`;

const Logo = styled.img`
  margin-bottom: 3em;
  height: 72px;
  width: auto;
`;

const Content = styled.div`
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  ${({ theme }) => theme.media.min.mobile`
     margin-top: 100px;
   `}
`;
