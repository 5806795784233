import { formatHeight } from '@allurion/domain';
import { Card } from '@allurion/ui';
import { toLocalizedLongDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useLocale } from 'src/hooks/useLocale';
import translations from 'src/messages/translations';

import styles from './PatientProfileCard.module.scss';

type Props = {
  patient: PatientProfile;
};

export function PatientProfileCard({ patient }: Props) {
  const intl = useIntl();
  const { locale } = useLocale();
  const { heightUnit } = useCurrentProvider();

  return (
    <Card title={intl.formatMessage(translations.personalInformation)}>
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.firstName',
          defaultMessage: 'First Name',
        })}
        value={patient?.name ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.lastName',
          defaultMessage: 'Last Name',
        })}
        value={patient?.lastname ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.email',
          defaultMessage: 'Email Address',
        })}
        value={patient?.email_address ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'gender' })}
        value={patient?.gender ? intl.formatMessage({ id: `gender.${patient?.gender}` }) : ''}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'dob', defaultMessage: 'Date of birth' })}
        value={toLocalizedLongDate(patient?.birthday, locale) || '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'height' })}
        value={formatHeight(patient?.height, heightUnit)}
      />
    </Card>
  );
}

function PersonalInformation({ label, value }: { label: string; value: string }) {
  return (
    <div className={styles.personalInformation}>
      <span className={styles.personalInformationLabel}>{label}</span>
      <span className={styles.personalInformationValue}>{value}</span>
    </div>
  );
}
