import colors from 'src/styles/theme/colors';

import messages from './weight-loss-by-percentage-chart.messages';

const dashedLine = (color: string, isMobile: boolean) => ({
  backgroundColor: 'transparent',
  borderDash: isMobile ? [6, 6] : [12, 12],
  pointBackgroundColor: 'transparent',
  pointBorderColor: color,
  hoverBackgroundColor: color,
  pointRadius: isMobile ? 3 : 5,
  pointHoverRadius: isMobile ? 3 : 6,
});

// eslint-disable-next-line import/prefer-default-export
export const dataSets = (data: any, isMobile: boolean, intl: any) => [
  {
    label: `95th ${intl.formatMessage(messages.Percentile)} TBWL`,
    data: data?.['95th Percentile']?.map((dataItem: any, index: number) => ({
      x: index + 1,
      y: dataItem,
    })),
    borderColor: colors.MintGreen,
    fill: true,
    ...dashedLine(colors.MintGreen, isMobile),
  },
  {
    label: `75th ${intl.formatMessage(messages.Percentile)} TBWL`,
    data: data?.['75th Percentile']?.map((dataItem: any, index: number) => ({
      x: index + 1,
      y: dataItem,
    })),
    borderColor: colors.GraphBlue,
    ...dashedLine(colors.GraphBlue, isMobile),
  },
  {
    label: `${intl.formatMessage(messages.Median)} TBWL`,
    data: data?.Median?.map((dataItem: any, index: number) => ({
      x: index + 1,
      y: dataItem,
    })),
    backgroundColor: 'transparent',
    pointBackgroundColor: 'transparent',
    pointBorderColor: colors.DarkBlue,
    hoverBackgroundColor: colors.DarkBlue,
    pointRadius: isMobile ? 3 : 5,
    pointHoverRadius: isMobile ? 3 : 6,
    borderColor: colors.DarkBlue,
    borderWidth: 3,
  },
  {
    label: `25th ${intl.formatMessage(messages.Percentile)} TBWL`,
    data: data?.['25th Percentile']?.map((dataItem: any, index: number) => ({
      x: index + 1,
      y: dataItem,
    })),
    borderColor: colors.SeaGreen,
    ...dashedLine(colors.SeaGreen, isMobile),
  },
  {
    label: `5th ${intl.formatMessage(messages.Percentile)} TBWL`,
    data: data?.['5th Percentile']?.map((dataItem: any, index: number) => ({
      x: index + 1,
      y: dataItem,
    })),
    borderColor: colors.DarkGrey,
    ...dashedLine(colors.DarkGrey, isMobile),
  },
];
