import { isNull } from '@allurion/utils';
import z from 'zod';

export const atRiskDisplaySchema = z.enum(['At Risk', 'Not At Risk']).nullable();

export type AtRiskDisplay = z.infer<typeof atRiskDisplaySchema>;

const AT_RISK_THREADSHOLD = 0.5;

const atRisk = {
  'At Risk': true,
  'Not At Risk': false,
};

export function isPatientAtRisk(atRiskScore: number | null, atRiskDisplay: AtRiskDisplay) {
  return !isNull(atRiskDisplay)
    ? atRisk[atRiskDisplay]
    : !isNull(atRiskScore)
      ? atRiskScore > AT_RISK_THREADSHOLD
      : null;
}
