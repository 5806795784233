import z from 'zod';
import { atRiskDisplaySchema } from './risk';

const clinicPatientSchema = z.object({
  uid: z.string(),
  name: z.string(),
  lastname: z.string().nullable(),
  legacy_age: z.number().nullable(),
  age: z.number().nullable(),
  birthday: z.string().nullable(),
  active_patient: z.boolean(),
  uuid: z.string().nullable(),
  starting_weight: z.number().nullable(),
  current_weight: z.number().nullable(),
  starting_bmi: z.number().nullable(),
  current_bmi: z.number().nullable(),
  weight_loss_since_placement: z.number().nullable(),
  perc_weight_loss_since_placement: z.number().nullable(),
  days_in_program: z.number().nullable(),
  userid: z.string().nullable(),
  at_risk_score: z.number().nullable(),
  at_risk_display: atRiskDisplaySchema,
  latest_treatment_id: z.string().nullable(),
  latest_treatment_start_date: z.string().nullable(),
  latest_treatment_category_id: z.string().nullable(),
  last_weigh_in: z.string().nullable(),
});

export const clinicPatientsSchema = z.array(clinicPatientSchema);

export type ClinicPatient = z.infer<typeof clinicPatientSchema>;

export function hoistPatientsWithNoTreatments(patients: ClinicPatient[]) {
  const patientsWithNoTreatments = patients.filter((patient) => !patient.latest_treatment_id);
  const patientsWithTreatments = patients.filter((patient) => patient.latest_treatment_id);

  return [...patientsWithNoTreatments, ...patientsWithTreatments];
}
