import { ReactNode } from 'react';
import { LeftIcon, CloseIcon } from '../../icons/index';
import { Button, ButtonProps } from '../Button/Button';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import styles from './PageHeader.module.scss';

export type PageHeaderProps = {
  /** Component title */
  title?: string;
  /** Component subtitle */
  subtitle?: ReactNode;
  /** NavButtonVariant */
  navButtonVariant?: 'back' | 'close';
  /** Nav Button Action  */
  onNavButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** Array of icon buttons */
  iconButtons?: IconButtonProps[];
  /** Button Component */
  button?: ButtonProps;
  /** Has border bottom */
  hasBorder?: boolean;
  extraContent?: ReactNode;
  isRlt?: boolean;
};
/**
 * Primary UI component for user interaction
 */
export function PageHeader({
  title,
  onNavButtonClick,
  iconButtons,
  button,
  subtitle,
  hasBorder = true,
  navButtonVariant = 'back',
  extraContent,
  isRlt,
  ...props
}: PageHeaderProps) {
  const MAX_ICON_BUTTONS = 4;

  return (
    <div
      className={`${styles.pageHeader} ${!hasBorder ? styles.pageHeader__noBorder : ''}`}
      {...props}
    >
      <div className={styles.titleWrapper}>
        {onNavButtonClick && (
          <IconButton
            variant="icon"
            size="sm"
            icon={navButtonVariant === 'back' ? <LeftIcon /> : <CloseIcon />}
            onClick={onNavButtonClick}
            className={styles.navButton}
          />
        )}
        <div className={styles.titleWrapper__container}>
          <h1>{title}</h1>
          <span>{subtitle}</span>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        {iconButtons
          ?.slice(0, MAX_ICON_BUTTONS)
          .map((button, index) => <IconButton key={index} variant="icon" {...button} />)}
        {button && <Button {...button} />}
      </div>
      {extraContent && <div className={styles.extraWrapper}>{extraContent}</div>}
    </div>
  );
}
