import { useLayoutEffect, useState } from 'react';

import { useEventListener } from 'src/hooks/useEventListener';

export function useThrottledWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }
  useLayoutEffect(() => {
    updateSize();
  }, []);
  useEventListener('resize', updateSize);

  return size;
}
