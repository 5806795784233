import styled from 'styled-components';

import { WarningIcon } from 'src/icons/WarningIcon';

const StyledError = styled.div<any>`
  display: flex;
  justify-content: start;
  text-align: left;
  svg {
    min-width: 24px;
  }
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 12px;
  padding-left: 0;
  transition:
    height 0.5s,
    opacity 0.3s ease 0.2s;
  height: ${({ isVisible }) => (isVisible ? 18 : 0)}px;
  font-family: 'GT-America-Standard', sans-serif;
  &.error {
    height: auto;
  }
`;

const ErrorLabel = styled.span`
  vertical-align: super;
  padding-left: 6px;
`;

type Props = {
  label: string | undefined;
  show?: boolean | undefined;
  className?: string;
};

export function AllurionErrorIndicator({ label, show = true, className = '', ...props }: Props) {
  const isVisible = show && label;

  return (
    <StyledError className={`${className} error`} show={isVisible} {...props} data-sentry-unmask>
      {isVisible && <WarningIcon />}
      <ErrorLabel>{label}</ErrorLabel>
    </StyledError>
  );
}
