// TODO: Add suffix *Route to constants
export const resetPasswordRoute = '/reset-password';
export const toClinicPage = (clinicId: string) => `/clinic/${clinicId}`;
export const toClinicSettings = (clinicId: string) => `/clinic/${clinicId}/settings`;
export const profileRoute = '/profile';
export const managePatientsPage = (clinicId: string) => `/clinic/${clinicId}/organize-patients`;
export const messageNotificationsRoute = '/message-notifications';
export const toClinicPatientsPage = (clinicId: string) => `/clinic/${clinicId}/patients`;
export const toClinicAnalytics = (clinicId: string) => `/clinic/${clinicId}/analytics`;
export const toClinicProviders = (clinicId: string) => `/clinic/${clinicId}/members`;
