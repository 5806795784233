import { useEffect, useRef } from 'react';

type EventHandler = (e: unknown) => void;

export function useEventListener(
  eventName: string,
  handler: EventHandler,
  throttle = 66,
  useWindow = true // window | document
) {
  const savedHandler = useRef<EventHandler>();
  const resizeTimeout = useRef<ReturnType<typeof setTimeout> | null>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const element = useWindow ? window : document;
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: any) => {
      if (!resizeTimeout.current) {
        resizeTimeout.current = setTimeout(() => {
          resizeTimeout.current = null;
          // The setDimensions will execute at a rate of 15fps@66ms
          savedHandler.current?.(event);
        }, throttle);
      }
    };

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    // eslint-disable-next-line
    return () => {
      element.removeEventListener(eventName, eventListener);
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [eventName, throttle, useWindow]);
}
