import { Article } from '@allurion/domain';
import { toLocalizedDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { useLocale } from 'src/hooks/useLocale';

import { SendArticleButton } from './SendArticleButton';

import styles from './ContentArticlesCards.module.scss';

type Props = {
  patientId: string;
  articles: Article[];
  isLoading: boolean;
  reload: () => Promise<any>;
  isSubscribed: boolean;
};

export function ContentArticlesCards({ patientId, articles, reload, isSubscribed }: Props) {
  const intl = useIntl();
  const { locale } = useLocale();

  function openArticlePreview(article: Article) {
    window.open(article.url, '_blank');
  }

  return (
    <div className={styles.cards}>
      {articles.map((article) => (
        <div key={article.id} className={styles.card}>
          <div className={styles.title}>{article.title}</div>
          {isSubscribed && (
            <div className={styles.detail}>
              <span>
                {intl.formatMessage({
                  id: 'content-library.articles-table.scheduled',
                  defaultMessage: 'Scheduled',
                })}
              </span>
              <span>{toLocalizedDate(article.notificationDate, locale) || '-'}</span>
            </div>
          )}
          <div className={styles.detail}>
            <span>
              {intl.formatMessage({
                id: 'content-library.articles-table.sent',
                defaultMessage: 'Sent',
              })}
            </span>
            <span>{toLocalizedDate(article.sentDate, locale) || '-'}</span>
          </div>
          <div className={styles.detail}>
            <span>
              {intl.formatMessage({
                id: 'content-library.articles-table.viewed',
                defaultMessage: 'Viewed',
              })}
            </span>
            <span>{toLocalizedDate(article.viewDate, locale) || '-'}</span>
          </div>
          <div style={{ display: 'inline-flex', gap: '10px' }}>
            <TrackedButton
              type="button"
              variant="secondary"
              size="xs"
              label="Preview"
              onClick={() => openArticlePreview(article)}
              trackLabel="preview-article"
            />
            <SendArticleButton patientID={patientId} article={article} reload={reload} />
          </div>
        </div>
      ))}
    </div>
  );
}
