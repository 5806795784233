import { PowerOffIcon, Tooltip, useConfirmationDialog } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { PatientProfile } from 'src/domain/patient/Patients';
import { makePatientActive, makePatientInactive } from 'src/hooks/usePatientProfile';
import globalMessages from 'src/messages/global.messages';

type Props = {
  patient: PatientProfile;
  onChange: () => void;
};

export function ToggleActiveInactive({ patient, onChange }: Props) {
  const intl = useIntl();
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();
  const [isLoading, setIsLoading] = useState(false);
  const patientId = patient.uid.toString();

  return (
    <>
      <Loader isLoading={isLoading} />
      <ConfirmationDialog />
      {patient.active_patient ? (
        <Tooltip
          text={intl.formatMessage({
            id: 'patient-page.make-inactive-tooltip',
            defaultMessage: 'Make inactive',
          })}
          placement="bottom"
        >
          <TrackedIconButton
            size="sm"
            variant="secondary"
            icon={<PowerOffIcon />}
            onClick={makeInactive}
            trackLabel="make-inactive-patient"
          />
        </Tooltip>
      ) : (
        <Tooltip
          text={intl.formatMessage({
            id: 'patient-page.make-active-tooltip',
            defaultMessage: 'Make active',
          })}
          placement="bottom"
        >
          <TrackedIconButton
            size="sm"
            variant="primary"
            icon={<PowerOffIcon />}
            onClick={makeActive}
            trackLabel="make-active-patient"
          />
        </Tooltip>
      )}
    </>
  );

  async function makeActive() {
    if (!patientId) {
      return;
    }

    const shouldMakeActive = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'patient-profile.make-active-dialog-title',
        defaultMessage: 'Make active?',
      }),
      message: (
        <>
          {intl.formatMessage({
            id: 'patient-profile.make-active-dialog-message',
            defaultMessage: 'You can add treatments and see incoming data from Active patients.',
          })}
          <br />
          <br />
          {intl.formatMessage({
            id: 'patient-profile.make-active-dialog-second-message',
            defaultMessage: 'Active patients will be added to your invoice.',
          })}
          <br />
          <br />
        </>
      ),
      confirmText: intl.formatMessage({
        id: 'patient-profile.make-active-dialog-confirm-btn',
        defaultMessage: 'Make active',
      }),
      cancelText: intl.formatMessage(globalMessages.cancel),
    });

    if (!shouldMakeActive) {
      return;
    }

    setIsLoading(true);
    await makePatientActive(patientId);
    await onChange();
    setIsLoading(false);
  }

  async function makeInactive() {
    if (!patientId) {
      return;
    }

    const shouldMakeInactive = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'patient-profile.make-inactive-dialog-title',
        defaultMessage: 'Make inactive?',
      }),
      message: (
        <>
          {intl.formatMessage({
            id: 'patient-profile.make-inactive-dialog-message',
            defaultMessage:
              'You cannot add treatments or see incoming data from Inactive patients. By default they do not appear on the Patients page, but you can change your filter settings to view them.',
          })}
          <br />
          <br />
          {intl.formatMessage({
            id: 'patient-profile.make-inactive-dialog-second-message',
            defaultMessage: 'You can always re-activate patients from their profiles.',
          })}
          <br />
          <br />
        </>
      ),
      confirmText: intl.formatMessage({
        id: 'patient-profile.make-inactive-dialog-confirm-btn',
        defaultMessage: 'Make Inactive',
      }),
      cancelText: intl.formatMessage(globalMessages.cancel),
    });

    if (!shouldMakeInactive) {
      return;
    }

    setIsLoading(true);
    await makePatientInactive(patientId);
    await onChange();
    setIsLoading(false);
  }
}
