import { useMemo, useState } from 'react';

type PaginationState = {
  page: number;
  pageSize: number;
};

export function usePagination(tableName: string, defaultState: Partial<PaginationState> = {}) {
  const paginationKey = `pagination-${tableName}`;
  const initalState = useMemo<PaginationState>(() => {
    const state = sessionStorage.getItem(paginationKey);

    let parsedState: PaginationState = {
      page: 1,
      pageSize: 10,
      ...defaultState,
    };

    if (state) {
      parsedState = {
        ...parsedState,
        ...JSON.parse(state),
      } as PaginationState;
    }

    return parsedState;
  }, [defaultState, paginationKey]);

  const [{ page, pageSize }, setState] = useState<PaginationState>(initalState);

  function setPage(newPage: number) {
    setState((prevState) => {
      if (prevState.page === newPage) {
        return prevState;
      }

      const nextState = { ...prevState, page: newPage };

      sessionStorage.setItem(paginationKey, JSON.stringify(nextState));

      return nextState;
    });
  }

  function setPageSize(newPageSize: number) {
    setState((prevState) => {
      if (prevState.pageSize === newPageSize) {
        return prevState;
      }

      const nextState = { ...prevState, pageSize: newPageSize };

      sessionStorage.setItem(paginationKey, JSON.stringify(nextState));

      return nextState;
    });
  }

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    defaultPageSize: initalState.pageSize,
    paginateData: function <DT>(data: DT[]) {
      return data.slice((page - 1) * pageSize, page * pageSize);
    },
    getLastPage(data: unknown[]) {
      return Math.ceil(data.length / pageSize);
    },
  };
}
