import { useMemo } from 'react';

import ArabicMessages from 'src/lang/ar.json';
import CatalanMessages from 'src/lang/ca.json';
import DanishMessages from 'src/lang/da.json';
import GermanMessages from 'src/lang/de.json';
import EnglishMessages from 'src/lang/en.json';
import EnglishUKMessages from 'src/lang/en_GB.json';
import SpanishLaMessages from 'src/lang/es_419.json';
import SpanishEsMessages from 'src/lang/es_ES.json';
import FrenchMessages from 'src/lang/fr.json';
import ItalianMessages from 'src/lang/it.json';
import DutchMessages from 'src/lang/nl.json';
import PortugueseMessages from 'src/lang/pt_BR.json';
import SwedishMessages from 'src/lang/sv.json';
import TurkishMessages from 'src/lang/tr.json';
import ChineseCnMessages from 'src/lang/zh_CN.json';
import ChineseTwMessages from 'src/lang/zh_TW.json';

interface ILocaleMessage {
  [key: string]: Record<string, string>;
}

const LocaleMessages: ILocaleMessage = {
  ar: ArabicMessages,
  ca: CatalanMessages,
  da: DanishMessages,
  de: GermanMessages,
  en: EnglishMessages,
  en_gb: EnglishUKMessages,
  es_419: SpanishLaMessages,
  es_es: SpanishEsMessages,
  fr: FrenchMessages,
  it: ItalianMessages,
  nl: DutchMessages,
  pt_br: PortugueseMessages,
  sv: SwedishMessages,
  tr: TurkishMessages,
  zh_cn: ChineseCnMessages,
  zh_tw: ChineseTwMessages,
};

/* Define mapping for languages where we don't have "base" translation file,
   base meaning just two letter language eg: We currently don't have zh just zh_cn and zh_tw
   so this helps sets zh_cn as zh
*/
const PREFERRED_LANGUAGES_MAPPING: Record<
  string,
  { locale: string; messages: Record<string, string> }
> = {
  es: { locale: 'es-419', messages: SpanishLaMessages as Record<string, string> },
  pt: { locale: 'pt-br', messages: PortugueseMessages as Record<string, string> },
  zh: { locale: 'zh-cn', messages: ChineseCnMessages as Record<string, string> },
};

export const getCorrespondingLocalConfig = (locale: string) => {
  // To make sure we always match with dict keys which are declared useing _ and lowercase
  const cleanedLocale = locale.replace('-', '_').toLowerCase();
  const correspondingMessages = LocaleMessages[cleanedLocale];

  if (correspondingMessages) {
    return { locale, messages: correspondingMessages };
  }

  const twoLetterLocale = cleanedLocale.substring(0, 2);

  const correspondingPreferredMessages = PREFERRED_LANGUAGES_MAPPING[twoLetterLocale];

  if (correspondingPreferredMessages) {
    return correspondingPreferredMessages;
  }

  // If we still can't find it lets try to find the base language
  const correspondingBaseMessages = LocaleMessages[twoLetterLocale];

  if (correspondingBaseMessages) {
    return {
      locale,
      messages: correspondingBaseMessages,
    };
  }

  // If all else fails return english
  return { locale: 'en', messages: EnglishMessages as Record<string, string> };
};

export function useLocale() {
  const locale = navigator.language ?? 'en';
  const localConfig = useMemo(
    () =>
      getCorrespondingLocalConfig(locale) ?? {
        locale: 'en',
        messages: EnglishMessages as Record<string, string>,
      },
    [locale]
  );

  const { locale: configLocale, messages } = localConfig;

  return { configLocale, messages, locale };
}
