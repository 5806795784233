import { ClinicPatient, formatWeight } from '@allurion/domain';
import { daysSince, isNull, toLocalizedDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { fixNumbers } from 'src/helpers/convertions';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import messages from 'src/pages/PatientPage/Overview/PatientTreamentsCard/PatientTreamentsCard.translations';

import { ValueWithArrow } from './ValueWithArrow';
import { ValueWithCalendar } from './ValueWithCalendar';

export function usePatientFormatters(clinicId: string) {
  const { treatmentList } = useClinicTreatements(clinicId);
  const { getTreatmentCategoryLabel, getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { weightUnit } = useCurrentProvider();
  const intl = useIntl();

  return {
    getPatientFullName,
    getPatientFullNameAndAge(patient: ClinicPatient) {
      return [getPatientFullName(patient), patient.age].filter(Boolean).join(', ');
    },
    getTreatmentStartDateLabel(treatmentCategoryId: number | string | undefined) {
      /*
      1 Allurion Balloon Program: Date of balloon placement
      2 Endoscopic Balloon: Date of balloon placement
      3 Surgery: Date of surgical procedure
      4 Obesity Management Medication: Date of first dose
      5 Lifestyle: Program start date
      */
      const key = `treatmentStartDate${treatmentCategoryId}` as keyof typeof messages;
      const message = messages[key];

      if (message) {
        return intl.formatMessage(message);
      }

      return intl.formatMessage(messages[`treatmentStartDate5`]);
    },
    getPatientTreatmentLabel(patient: ClinicPatient) {
      if (!patient.latest_treatment_id) {
        return (
          <span style={{ color: '#96282A' }}>
            {intl.formatMessage({
              id: 'patient.noTreatment',
              defaultMessage: 'Please Add Treatment',
            })}
          </span>
        );
      }

      const treatment = treatmentList.find(
        (t) => t.custom_treatment_id === patient.latest_treatment_id?.toString()
      );

      if (!treatment) {
        if (patient.latest_treatment_category_id) {
          return `${getTreatmentCategoryLabel(patient.latest_treatment_category_id, 'short')} / ${getTreatmentLabel(patient.latest_treatment_id, 'short')}`;
        }

        return '';
      }

      return `${getTreatmentCategoryLabel(treatment.treatment_type_id, 'short')} / ${getTreatmentLabel(treatment.custom_treatment_id, 'short')}`;
    },
    getPatientCurrentWeight(patient: ClinicPatient) {
      if (!patient.current_weight) {
        return '';
      }

      const absValue = Math.abs(patient.current_weight);

      return formatWeight(absValue, weightUnit);
    },
    getPatientCurrentBmi(patient: ClinicPatient) {
      if (!patient.current_bmi) {
        return '';
      }

      return fixNumbers(patient.current_bmi.toString(), 1, 1);
    },
    getPatientWeightLossSincePlacement(patient: ClinicPatient) {
      const value = patient.weight_loss_since_placement;

      if (isNull(value)) {
        return '';
      }

      const absValue = value === 0 ? 0 : Math.abs(value);

      return <ValueWithArrow value={value} label={formatWeight(absValue, weightUnit)} />;
    },
    getPatientTBWL(patient: ClinicPatient) {
      const value = patient.perc_weight_loss_since_placement;

      if (isNull(value)) {
        return '';
      }

      if (value === 0) {
        return `0 %`;
      }

      const absValue = Math.abs(value);

      return <ValueWithArrow value={value} label={`${fixNumbers(absValue.toString(), 1, 1)} %`} />;
    },
    getPatientDaysInTreatment(patient: ClinicPatient) {
      if (!patient.latest_treatment_start_date) {
        return '';
      }

      const value = daysSince(patient.latest_treatment_start_date);

      if (!value || value < 0) {
        return '';
      }

      return <ValueWithCalendar value={value} />;
    },
    getLastWeightIn(patient: ClinicPatient, locale: string) {
      const value = patient.last_weigh_in;

      if (!value) {
        return '';
      }

      return toLocalizedDate(value, locale);
    },
  };

  function getPatientFullName(patient: ClinicPatient) {
    const names = [patient.name, patient.lastname]
      .map((v) => v?.trim())
      .filter(Boolean)
      .join(' ');

    return names;
  }
}
