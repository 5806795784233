import { classNames } from '../../utils';
import styles from './Badge.module.scss';

type Props = {
  /** How the badge style should be? */
  variant?:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'success'
    | 'danger'
    | 'success-light'
    | 'danger-light';
  /** Badge contents */
  label?: string;
  /** Left icon component */
  leftIcon?: React.ReactNode | null;
  /** Right icon component */
  rightIcon?: React.ReactNode | null;
  size?: 'sm' | 'md';
};

export function Badge({ leftIcon, label, rightIcon, variant = 'primary', size = 'md' }: Props) {
  const className = classNames(styles.badge, styles[`badge-${variant}`], styles[`badge-${size}`]);

  return (
    <span className={className}>
      {leftIcon} {label} {rightIcon}
    </span>
  );
}
