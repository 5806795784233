import baseTooltip from 'src/charts/ChartJS/chart-tooltip';

export const tooltips: any = {
  ...baseTooltip,
  callbacks: {
    title: () => null,
    label: (tooltipItem: any) => `${tooltipItem?.parsed?.y}`,
  },
  mode: 'nearest',
};

export default tooltips;
