import { Article } from '@allurion/domain';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';

import { useLocale } from './useLocale';

export const PATIENT_ARTICLES = (patientId: string) => `/patients/${patientId}/articles`;

export function usePatientArticles(patientId?: string) {
  const { locale } = useLocale();
  const url = patientId && buildUrl(PATIENT_ARTICLES(patientId), { 'locale': locale });
  const { data, error, isValidating, mutate } = useSWR(
    url,
    (_url: string) => InsightsApi.get<{ articles: Article[] }>(_url).then((res) => res.articles),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { isLoading: isValidating, articles: data ?? [], error, reload: mutate };
}
