import { getContentfulClient } from './contentful-client';

export function fetchLocales() {
  return getContentfulClient()
    .getLocales()
    .then((res) => {
      if (!res.items || res.items.length === 0) {
        return [];
      }

      return res.items.map((item) => item.code);
    });
}

export function useContentfulLocaleOrFallback(validLocales: string[], targetLocale = 'en-GB') {
  const [langCode, countryCode] = targetLocale.split(/[-_]/);

  if (!langCode) {
    return 'en-GB';
  }

  const normalizedLocale = countryCode
    ? `${langCode.toLowerCase()}-${countryCode.toUpperCase()}`
    : langCode.toLowerCase();

  if (countryCode && validLocales.includes(normalizedLocale)) {
    return normalizedLocale;
  }

  const fallbackLocale = validLocales.find((locale) => locale.startsWith(langCode));

  return fallbackLocale ?? 'en-GB';
}
