import { Suspense } from 'react';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Sidebar } from 'src/components/ui/Sidebar';

import { PatientNotes } from '../Notes/PatientNotes';
import { PatientVideoCall } from '../VideoCall/PatientVideoCall';

import styles from './PatientSidebar.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedButton?: string;
  setActiveButton: (button: string) => void;
};

export function PatientSidebar({ isOpen, selectedButton, setIsOpen, setActiveButton }: Props) {
  if (!isOpen) {
    return;
  }

  return (
    <div className={styles.sidebar}>
      <Sidebar isOpen={isOpen}>
        {selectedButton === 'notes' && (
          <Suspense fallback={<InlineLoader />}>
            <PatientNotes onClose={onClose} />
          </Suspense>
        )}
        {selectedButton === 'video' && (
          <Suspense fallback={<InlineLoader />}>
            <PatientVideoCall onClose={onClose} />
          </Suspense>
        )}
      </Sidebar>
    </div>
  );

  function onClose() {
    setIsOpen!(false);
    setActiveButton!('');
  }
}
