import { StepsIcon, SleepIcon, ExcerciseIcon } from '@allurion/ui';
import { minutesToHMM } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useActivityData } from 'src/hooks/usePatientActivityData';
import { usePatientExercise } from 'src/hooks/usePatientExercise';
import { usePatientSleep } from 'src/hooks/usePatientSleep';
import { usePatientSteps } from 'src/hooks/usePatientSteps';

import messages from './activity.messages';
import { ActivityCard } from './ActivityCard';

import styles from './ActivityCard.module.scss';

type Props = {
  patientId: string;
};

export function PatientActivities({ patientId }: Props) {
  const intl = useIntl();
  const {
    isLoading: isLoadingSleepData,
    fetchMore: fetchMoreSleepData,
    sleepData,
  } = usePatientSleep(patientId);
  const {
    isLoading: isLoadingStepsData,
    fetchMore: fetchMoreStepsData,
    stepsData,
  } = usePatientSteps(patientId);
  const {
    isLoading: isLoadingExerciseData,
    fetchMore: fetchMoreExerciseData,
    exerciseData,
  } = usePatientExercise(patientId);
  const { steps, sleep, exercise } = useActivityData({
    sleepData,
    stepsData,
    exerciseData,
  });

  return (
    <div className={styles.container}>
      {isLoadingStepsData ? (
        <InlineLoader />
      ) : (
        <ActivityCard
          title={intl.formatMessage(messages.steps)}
          noDataTitle={intl.formatMessage(messages.noStepsDataTitle)}
          noDataSubtitle={intl.formatMessage(messages.noStepsDataSubtitle)}
          noDataTrackLabel="no-steps-data-found"
          averageValue={intl.formatMessage(
            {
              id: messages.numberSteps.id,
            },
            {
              steps: steps.average.toLocaleString(),
            }
          )}
          valueLabel={intl.formatMessage(messages.steps)}
          Icon={StepsIcon}
          data={steps.dataForList}
          graph={{
            graphId: 'steps-bar-chart',
            graphBarColor: '#66868C',
            graphData: steps.data,
            stepSize: 3000,
            ytickFormatter: (value) => value,
            tooltipLabelFormatter: (tooltipItem) => steps.formatter(tooltipItem?.parsed?.y),
          }}
          fetchMoreData={fetchMoreStepsData}
          isLoading={isLoadingStepsData}
        />
      )}
      {isLoadingSleepData ? (
        <InlineLoader />
      ) : (
        <ActivityCard
          title={intl.formatMessage(messages.sleep)}
          noDataTitle={intl.formatMessage(messages.noSleepDataTitle)}
          noDataSubtitle={intl.formatMessage(messages.noSleepDataSubtitle)}
          noDataTrackLabel="no-sleep-data-found"
          averageValue={minutesToHMM(sleep.average)}
          valueLabel={intl.formatMessage(
            {
              id: messages.amountSleep.id,
            },
            {
              format: '(HH-MM)',
            }
          )}
          Icon={SleepIcon}
          data={sleep.dataForList}
          graph={{
            graphId: 'sleep-bar-chart',
            graphBarColor: '#5866A1',
            graphData: sleep.data,
            stepSize: 2, // 2 hour sincrements
            ytickFormatter: (hours) => hours,
            tooltipLabelFormatter: (tooltipItem) => sleep.formatter(tooltipItem.parsed.y * 60),
          }}
          fetchMoreData={fetchMoreSleepData}
          isLoading={isLoadingSleepData}
        />
      )}

      {isLoadingExerciseData ? (
        <InlineLoader />
      ) : (
        <ActivityCard
          title={intl.formatMessage(messages.excercise)}
          noDataTitle={intl.formatMessage(messages.noExerciseDataTitle)}
          noDataSubtitle={intl.formatMessage(messages.noExerciseDataSubtitle)}
          noDataTrackLabel="no-exercise-data-found"
          averageValue={exercise.formatter(exercise.average)}
          valueLabel={intl.formatMessage(messages.time)}
          middleValueLabel={intl.formatMessage({
            id: messages.activityLabel.id,
          })}
          Icon={ExcerciseIcon}
          data={exercise.dataForList}
          graph={{
            graphId: 'excercise-bar-chart',
            graphBarColor: '#825252',
            graphData: exercise.data,
            stepSize: 60 * 30, // 15min increments
            ytickFormatter: (value) => {
              const minutes = Math.floor(value / 60);

              return `${minutes}: 00`;
            },
            tooltipLabelFormatter: (tooltipItem) => exercise.formatter(tooltipItem.parsed.y),
          }}
          fetchMoreData={fetchMoreExerciseData}
          isLoading={isLoadingExerciseData}
        />
      )}
    </div>
  );
}
