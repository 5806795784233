export function FilterIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 4.5L14 4.5"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.5H9"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <circle cx="4" cy="4.5" r="2.45" stroke="currentColor" strokeWidth="1.1" />
      <circle cx="12" cy="11.5" r="2.45" stroke="currentColor" strokeWidth="1.1" />
    </svg>
  );
}
