import { useModal } from './Modal';

import styles from './ConfirmationDialog.module.scss';
import { Button } from '../Button/Button';
import { classNames } from '../../utils';

export function useConfirmationDialog() {
  const { open, Modal } = useModal({
    content: Confirmation,
  });

  return {
    askConfirmation: open,
    askConfirmationPromise: (props: Omit<Props, 'onConfirm' | 'onCancel'>) => {
      return new Promise((resolve) => {
        open({
          ...props,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });
    },
    ConfirmationDialog: Modal,
  };
}

type Props = {
  title: string;
  message?: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  close?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'default' | 'danger';
};

function Confirmation({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  close,
  variant = 'default',
}: Props) {
  return (
    <div className={styles.container}>
      <h2 className={classNames(styles.title, !message && styles.centeredTitle)}>{title}</h2>
      {message && <p className={styles.message}>{message}</p>}

      <div className={styles.buttons}>
        <Button
          type="button"
          variant={variant === 'danger' ? 'danger' : 'primary'}
          onClick={handleConfirm}
          label={confirmText}
        />
        <Button type="button" variant="secondary" onClick={handleCancel} label={cancelText} />
      </div>
    </div>
  );

  function handleConfirm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onConfirm();
    close?.(e);
  }

  function handleCancel(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onCancel?.();
    close?.(e);
  }
}
