import { classNames } from '../../utils';
import styles from './TextArea.module.scss';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  invalid?: boolean;
  className?: string;
}

export function TextArea({ className, invalid, ...props }: TextAreaProps) {
  return (
    <textarea
      className={classNames(
        styles['text-area'],
        props.disabled ? styles['text-area-disabled'] : '',
        invalid ? styles['text-area-invalid'] : '',
        className
      )}
      {...props}
    />
  );
}
