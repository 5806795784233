import { isString } from '@allurion/utils';
import { useField } from 'formik';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { WarningIcon } from 'src/icons/WarningIcon';
import globalMessages from 'src/messages/global.messages';

import { AllurionErrorIndicator } from './AllurionErrorIndicator';

const StyledTextField = styled.input.attrs((props) => ({
  type: props.type || 'text',
}))<any>`
  resize: none;
  width: 100%;
  font-family: 'GT-America-Standard', sans-serif;
  font-size: 16px;
  height: 100%;
  padding: 18px 14px 18px 14px;
  border-radius: 2px;
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.White : theme.colors.Silver} !important;
  border: none;
  outline: none;
`;

const Label = styled.label<any>`
  color: ${({ darkbg, theme }) => (!darkbg ? theme.colors.Primary : theme.colors.White)} !important;
  margin-bottom: 15px;
  font-size: 20px;
  display: inline-block;
  font-family: 'GT-America-Standard', sans-serif;
`;

const InputWrapper = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  height: 56px;
  border-radius: 2px;
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.White : theme.colors.Silver} !important;
  border: solid 1px
    ${({ theme, haserror }) =>
      haserror && isString(haserror) ? theme.colors.Alert : theme.colors.GreyBorder};
  &:focus-within {
    outline: none;
    border: solid 1px
      ${({ theme, haserror }) =>
        haserror && isString(haserror) ? theme.colors.Alert : theme.colors.Primary};
  }
`;

const Container = styled.div<any>`
  padding-top: 12px;
  padding-bottom: 12px;
  .requiredField {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.Alert};
    svg {
      margin-right: 5px;
    }
    span {
      font-size: 16px;
      font-family: 'GT-America-Standard-Regular', sans-serif;
    }
  }
`;

type Props = {
  label?: string | JSX.Element;
  icon?: JSX.Element;
  darkbg?: boolean;
  className?: string;
  cyErrorLabelSelector?: string;
  isRequired?: boolean;
  value?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export function AllurionTextField({
  label = '',
  icon,
  darkbg = false,
  className,
  cyErrorLabelSelector,
  isRequired,
  ...props
}: Props) {
  const [field, meta] = useField(props as any);
  const { error, touched } = meta || {};
  const intl = useIntl();

  return (
    <Container className={className} data-cy="textField">
      <Label darkbg={darkbg}>{label}</Label>
      <InputWrapper {...field} {...props} haserror={error}>
        <StyledTextField
          variant="outlined"
          InputProps={{ endAdornment: icon }}
          haserror={error}
          {...field}
          {...props}
        />
        {icon}
      </InputWrapper>
      <AllurionErrorIndicator
        label={error}
        show={!!(error || touched)}
        data-cy={cyErrorLabelSelector}
      />
      {isRequired && props.value === '' && (
        <div className="requiredField">
          <WarningIcon />
          <span>{intl.formatMessage(globalMessages.required)}</span>
        </div>
      )}
    </Container>
  );
}
