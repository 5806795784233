import { STAFF_ROLE_ID } from '@allurion/domain';
import { useConfirmationDialog } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { disableUser, updateProviderProfile, updateUserRole } from 'src/api/UserApi';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ParsedClinicData } from 'src/domain/Clinic';
import { ProviderForm } from 'src/forms/User/ProviderForm';
import userFormMessages from 'src/forms/User/user-form-messages';
import { useProvider } from 'src/hooks/useProvider';
import { useProviderRoleLabels } from 'src/hooks/useProviderRoleLabels';
import globalMessages from 'src/messages/global.messages';
import translations from 'src/pages/ManageUsers/ManageUsers.translations';
import { Logger } from 'src/services/Logger';

import { Container, InnerContainer } from '../shared-page-elements';

type Props = {
  onUpdate: (state: any) => void;
  onDeleted: (state: any) => void;
  goBack: () => void;
  clinics: ParsedClinicData[];
};

export function EditProvider({ onUpdate, onDeleted, goBack, clinics }: Props) {
  const { providerId } = useParams();
  const intl = useIntl();
  const [isUpdating, setIsUpdating] = useState(false);
  const { provider, isLoading } = useProvider(providerId);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const providerClinics = (provider?.ClinicID ?? '').split(',');
  const roleID = provider?.RoleID;

  const roleMap = useProviderRoleLabels();
  const providerRole = roleID && roleMap[roleID];

  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();

  const initialValues = useMemo(
    () => ({
      firstName: provider?.FirstName ?? '',
      lastName: provider?.LastName ?? '',
      email: provider?.EmailAddress ?? '',
      role: providerRole || {
        label: intl.formatMessage(translations.manageUsersStaff),
        value: STAFF_ROLE_ID,
      },
      clinics: providerClinics,
    }),
    [
      intl,
      provider?.EmailAddress,
      provider?.FirstName,
      provider?.LastName,
      providerClinics,
      providerRole,
    ]
  );

  const onSubmit = (values: any) => {
    updateProvider(values);
  };

  const disableProvider = async () => {
    if (!providerId) {
      return;
    }

    const shouldDeleteUser = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'edit-provider-page.delete-btn',
        defaultMessage: 'Are you sure you want to delete this user? All the data will be removed',
      }),
      confirmText: intl.formatMessage(globalMessages.delete),
      cancelText: intl.formatMessage(globalMessages.cancel),
      variant: 'danger',
    });

    if (!shouldDeleteUser) {
      return;
    }

    setIsUpdating(true);

    try {
      await disableUser(providerId);
      setIsUpdating(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessDeleteUser));
      onDeleted({
        formEmail: provider?.EmailAddress,
        formFirstName: provider.FirstName,
        formLastName: provider.LastName,
        clinicLabel: provider.ClinicID,
        roleLabel: providerRole.value,
      });
    } catch (error) {
      Logger.captureException(error);
      setIsUpdating(false);
      toastError(intl.formatMessage(translations.userFormWrapperDeleteUserFailure));
    }
  };

  const updateProvider = async (values: any) => {
    if (!providerId) {
      return;
    }

    setIsUpdating(true);
    const clinicLabel = values.clinics?.map(({ label }: any) => label).join(', ');
    const selectedClinics = values.clinics?.map(({ id }: any) => id) || [];

    try {
      await updateProviderProfile(
        providerId,
        values.firstName,
        values.lastName,
        provider?.UnitsPreference ?? 'kg'
      );
      if (provider?.CognitoUserName) {
        await updateUserRole(providerId, values.role.value, selectedClinics?.join());
      }
      setIsUpdating(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessEdit));
      trackFormSuccess('edit-provider');
      onUpdate({
        formEmail: provider?.EmailAddress,
        formFirstName: values.firstName,
        formLastName: values.lastName,
        clinicLabel,
        roleLabel: values.role.label,
      });
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      setIsUpdating(false);
      toastError(intl.formatMessage(translations.userFormWrapperEditUserFailure));
      trackFormError('edit-provider', { error: errorMessage });
    }
  };

  return (
    <>
      <TrackedPageHeader
        title={intl.formatMessage(translations.userFormWrapperEditUser)}
        onNavButtonClick={goBack}
        button={{
          label: intl.formatMessage(userFormMessages.deleteUser),
          onClick: () => disableProvider(),
          variant: 'secondary',
          trackLabel: 'delete-provider',
        }}
      />
      <ConfirmationDialog />
      <Container>
        <InnerContainer>
          <ProviderForm
            isEditForm
            isLoading={isLoading}
            initialValues={initialValues}
            onSubmit={onSubmit}
            allClinics={clinics!}
            providerClinics={providerClinics}
          />
          <Loader isLoading={isUpdating} />
        </InnerContainer>
      </Container>
    </>
  );
}
