import { scrollToTop } from '@allurion/ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Seo } from 'src/components/Seo';
import { InvitePatientApiPayload } from 'src/pages/InvitePatientPage/InvitePatient.types';

import { InvitePatientForm } from './InvitePatientForm';
import { InvitePatientSuccess } from './InvitePatientSuccess';

const initialValues: InvitePatientApiPayload = {
  firstName: '',
  lastName: '',
  email: '',
  patientType: '2',
  treatmentType: 0,
  startDate: '',
  lang: '',
  providers: [],
  collections: [],
};

export default function InvitePatientPage() {
  const { clinicId } = useParams();
  const [data, setData] = useState<InvitePatientApiPayload>({ ...initialValues });
  const [currentStep, setCurrentStep] = useState(0);

  if (!clinicId) {
    return null;
  }

  const steps = [
    <InvitePatientForm key="form-step" data={data} clinicId={clinicId} next={handleNextStep} />,
    <InvitePatientSuccess key="success-step" data={data} reset={reset} />,
  ];

  return (
    <>
      <Seo title="Add Patient" />
      {steps[currentStep]}
    </>
  );

  function handleNextStep(newData: InvitePatientApiPayload) {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
    scrollToTop();
  }

  function reset() {
    setData({ ...initialValues });
    setCurrentStep(0);
  }
}
