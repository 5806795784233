import { ComponentProps } from 'react';

import { SelectInput } from '../SelectInput/SelectInput';
import { TextInput } from '../TextInput/TextInput';

import styles from './InputGroup.module.scss';
import { classNames } from '../../utils';

type Props = {
  textInput?: ComponentProps<typeof TextInput>;
  selectInput?: ComponentProps<typeof SelectInput>;
  prefix?: React.ReactNode;
  sufix?: React.ReactNode;
};

export function InputGroup({ textInput, selectInput, prefix, sufix }: Props) {
  if (!textInput && !selectInput) {
    throw new Error('You must provide either a textInput or a selectInput props');
  }

  const size = (textInput?.size || selectInput?.size) ?? 'sm';

  const inputClassName = classNames(
    styles['input-group-input'],
    Boolean(prefix) && styles['input-group-input-with-prefix'],
    Boolean(sufix) && styles['input-group-input-with-sufix']
  );
  const inputGroupClassName = classNames(
    styles['input-group'],
    size && styles[`input-group-${size}`]
  );

  return (
    <div className={inputGroupClassName}>
      {prefix && <div className={styles['input-group-prefix']}>{prefix}</div>}

      {textInput ? (
        <TextInput {...textInput} className={inputClassName} />
      ) : selectInput ? (
        <SelectInput {...selectInput} className={inputClassName} />
      ) : null}

      {sufix && <div className={styles['input-group-sufix']}>{sufix}</div>}
    </div>
  );
}
