import { isNull, isUndefined, roundUpHalf } from '@allurion/utils';

export const WeightUnits = {
  Kg: 'kg',
  Lb: 'lb',
  Stone: 'stone',
} as const;
export type WeightUnit = (typeof WeightUnits)[keyof typeof WeightUnits];

export function isWeightUnit(unit: string): unit is WeightUnit {
  return Object.values(WeightUnits).includes(unit as WeightUnit);
}

export function formatWeight(value: number | null | undefined | string, unit: WeightUnit) {
  if (isUndefined(value) || isNull(value)) {
    return '-';
  }

  let convertedValue = convertWeight(value, unit).toFixed(1);

  if (convertedValue.startsWith('-0.')) {
    convertedValue = convertedValue.replace('-0.', '0.');
  }

  switch (unit) {
    case 'lb':
      return `${convertedValue} lb`;

    case 'stone':
      return `${convertedValue} st`;

    default:
      return `${convertedValue} kg`;
  }
}

export function convertWeight(value: number | string, targetUnit: WeightUnit) {
  const numericValue = Number(value);

  switch (targetUnit) {
    case 'lb':
      return toPounds(numericValue, WeightUnits.Kg);

    case 'stone':
      return toStone(numericValue, WeightUnits.Kg);

    default:
      return toKg(numericValue, WeightUnits.Kg);
  }
}

const kgPerPoundFactor = 0.453592;
const kgPerStoneFactor = 6.35029;
const lbPerStoneFactor = 14;

export function toKg(value: number, currentUnit: WeightUnit) {
  switch (currentUnit) {
    case 'lb':
      return roundUpHalf(value * kgPerPoundFactor, 2);

    case 'stone':
      return roundUpHalf(value * kgPerStoneFactor, 2);

    default:
      return value;
  }
}

export function toPounds(value: number, currentUnit: WeightUnit) {
  switch (currentUnit) {
    case 'kg':
      return roundUpHalf(value / kgPerPoundFactor, 2);

    case 'stone':
      return roundUpHalf(value * lbPerStoneFactor, 2);

    default:
      return value;
  }
}

export function toStone(value: number, currentUnit: WeightUnit) {
  switch (currentUnit) {
    case 'kg':
      return roundUpHalf(value / kgPerStoneFactor, 2);

    case 'lb':
      return roundUpHalf(value / lbPerStoneFactor, 2);

    default:
      return value;
  }
}
