import { useMemo, useState } from 'react';

import { PatientFilter } from './ClinicPatientsFilter';

const defaultState: PatientFilter = {
  status: 'active',
  treatment: '',
  provider: '',
  view: 'my',
};

export function useClinicPatientsFilters(clinicId: string | undefined): {
  filters: PatientFilter;
  updateFilters: (filters: PatientFilter) => void;
} {
  const filtersKey = clinicId && `filters-clinic-patients-${clinicId}`;
  const initalState = useMemo<PatientFilter>(() => {
    if (!filtersKey) {
      return defaultState;
    }

    const state = sessionStorage.getItem(filtersKey);

    const parsedState: PatientFilter = {
      ...defaultState,
      ...(state ? JSON.parse(state) : {}),
    };

    return parsedState;
  }, [filtersKey]);
  const [filters, setFilters] = useState<PatientFilter>(initalState);

  function updateFilters(filters: PatientFilter) {
    setFilters((prevFilters) => {
      const nextFilters = { ...prevFilters, ...filters };

      if (filtersKey) {
        sessionStorage.setItem(filtersKey, JSON.stringify(nextFilters));
      }

      return nextFilters;
    });
  }

  return {
    filters,
    updateFilters,
  };
}
